<template>
  <div class="preview-box">
    <iframe v-if="['pdf'].includes(type)" :src="fileSrc" frameborder="0" class="iframe-box"> </iframe>
    <viewer v-else-if="['png','jpg','jpeg','gif'].includes(type)" class="img-box">
      <img :src="fileSrc" alt="" class="img_pic" />
      <!-- <a-icon type="close" @click="deleteImg('identityCardBack')"/> -->
    </viewer>
  </div>
</template>
<script>
export default {
  props: {
    fileSrc: {
      type: String,
      require: true
    }
  },
  computed:{
    type() {
      if (this.fileSrc.indexOf('?') != -1) {
        return this.fileSrc.split('?')[0].split('.').pop().toLowerCase()
      } else if(this.fileSrc !== '' && this.fileSrc !== null && this.fileSrc !== undefined) {
        return 'pdf'
      } else {
        return ''
      }
    }
  },
}
</script>
<style scoped>
.preview-box{
  margin-top: 10px;
}
</style>