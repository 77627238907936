import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)
const routes = [
	{
		path: '/home',
		name: '首页',
		component: (resolve) => require(['@/views/home'], resolve),
		children: [
			{
				path: 'permission',
				name: 'menus.permission',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						path: 'role',
						name: 'menus.permissionMenu.role',
						component: (resolve) => require(['@/views/permission/role'], resolve)
					},
					{
						path: 'user',
						name: 'menus.permissionMenu.user',
						component: (resolve) => require(['@/views/permission/user'], resolve)
					},
					{
						name: 'menus.permissionMenu.reviewSetting',
						path: 'reviewSetting',
						component: (resolve) => require(['@/views/permission/reviewSetting'], resolve)
					}
				]
			},
			{
				name: 'menus.customerManagement',
				path: 'customerManagement',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.customerManagementMenu.clue',
						path: 'clue',
						component: (resolve) => require(['@/views/customerManagement/clue'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.intention',
						path: 'intention',
						component: (resolve) => require(['@/views/customerManagement/intention'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.intention-detail',
						path: 'intention-detail',
						component: (resolve) => require(['@/views/customerManagement/intention-detail'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.apply',
						path: 'apply',
						component: (resolve) => require(['@/views/customerManagement/apply'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.applydetail',
						path: 'applydetail',
						component: (resolve) => require(['@/views/customerManagement/applydetail'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.adsClients',
						path: 'adsClients',
						component: (resolve) => require(['@/views/customerManagement/adsClients'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.lending',
						path: 'lending',
						component: (resolve) => require(['@/views/customerManagement/lending'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.lendingdetail',
						path: 'lendingdetail',
						component: (resolve) => require(['@/views/customerManagement/lendingdetail'], resolve)
					},
					{
						name: 'menus.customerManagementMenu.loansrejected',
						path: 'loansrejected',
						component: (resolve) => require(['@/views/customerManagement/loansrejected'], resolve)
					}
				]
			},
			{
				name: 'menus.loanData',
				path: 'loandata',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						// 申请资料 list
						name: 'menus.loanDataMenu.applicationList',
						path: 'applicationlist',
						component: (resolve) => require(['@/views/loanData/applicationList'], resolve)
					},
					{
						// 申请资料 详情
						name: 'menus.loanDataMenu.applicationDetail',
						path: 'applicationdetail',
						component: (resolve) => require(['@/views/loanData/applicationDetail'], resolve)
					},
					{
						// 申请资料 新增
						name: 'menus.loanDataMenu.addApplication',
						path: 'addapplication',
						component: (resolve) => require(['@/views/loanData/addApplication'], resolve)
					},
					{
						// 申请资料 修改
						name: 'menus.loanDataMenu.editApplication',
						path: 'editapplication',
						component: (resolve) => require(['@/views/loanData/editApplication'], resolve)
					},
					{
						//   - 放款资料 list
						name: 'menus.loanDataMenu.loanList',
						path: 'loanlist',
						component: (resolve) => require(['@/views/loanData/loanList'], resolve)
					},
					{
						//   - 放款资料 录入
						name: 'menus.loanDataMenu.loanadd',
						path: 'loanadd',
						component: (resolve) => require(['@/views/loanData/loanAdd'], resolve)
					},
					{
						//   - 放款资料详情
						name: 'menus.loanDataMenu.loandeatil',
						path: 'loandeatil',
						component: (resolve) => require(['@/views/loanData/loanDetail'], resolve)
					},
					{
						//   - 待放款 list
						name: 'menus.loanDataMenu.pendingLoan',
						path: 'pendinglist',
						component: (resolve) => require(['@/views/loanData/pendingList'], resolve)
					},
					{
						//   - 待放款 详情
						name: 'menus.loanDataMenu.pendingDetail',
						path: 'pendingDetail',
						component: (resolve) => require(['@/views/loanData/pendingDetail'], resolve)
					},
					{
						//  Disburse Now
						name: 'menus.loanDataMenu.disburseNow',
						path: 'disburseNow',
						component: () => import('@/views/loanData/disburse-now.vue')
					},
					{
						// Record Disbursement Entries
						name: 'menus.loanDataMenu.recordDisbursementEntries',
						path: 'recordDisbursementEntries',
						component: () => import('@/views/loanData/record-disbursement-entries.vue')
					},
					{
						// Loan Application - Disbursement Records
						name: 'menus.loanDataMenu.loanApplicationDisbursementRecords',
						path: 'loanApplicationDisbursementRecords',
						component: () => import('@/views/loanData/disbursement-records.vue')
					},
					{
						// IPF - Disbursement Records
						name: 'menus.loanDataMenu.ipfDisbursementRecords',
						path: 'ipfDisbursementRecords',
						component: () => import('@/views/loanData/disbursement-records.vue')
					}
				]
			},
			{
				name: 'menus.employeeManagement',
				path: 'employeeManagement',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.employeeManagementMenu.salesman',
						path: 'salesman',
						component: (resolve) => require(['@/views/employeeManagement/salesman'], resolve)
					},
					{
						name: 'menus.employeeManagementMenu.branchman',
						path: 'branchman',
						component: (resolve) => require(['@/views/employeeManagement/branchman'], resolve)
					}
				]
			},
			{
				name: 'menus.loanReview',
				path: 'loanreview',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.loanMenu.applicationReview',
						path: 'applicationReview',
						component: (resolve) => require(['@/views/loanReview/applicationReview'], resolve)
					},
					{
						name: 'menus.loanMenu.applicationReviewDetail',
						path: 'applicationReviewDetail',
						component: (resolve) => require(['@/views/loanReview/applicationReviewDetail'], resolve)
					},
					{
						name: 'menus.loanMenu.loanDataReview',
						path: 'loanDataReview',
						component: (resolve) => require(['@/views/loanReview/loanDataReview'], resolve)
					},
					{
						name: 'menus.loanMenu.loanDataReviewDetail',
						path: 'loanDataReviewDetail',
						component: (resolve) => require(['@/views/loanReview/loanDataReviewDetail'], resolve)
					}
				]
			},
			{
				name: 'menus.gpsCar',
				path: 'gps',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.gpsMenu.list',
						path: 'list',
						component: (resolve) => require(['@/views/gps/gpsList'], resolve)
					},
					{
						name: 'menus.gpsMenu.report',
						path: 'report',
						component: (resolve) => require(['@/views/gps/gpsError'], resolve)
					},
					{
						name: 'menus.gpsMenu.path',
						path: 'path',
						component: (resolve) => require(['@/views/gps/gpsPath'], resolve)
					}
				]
			},
			{
				name: 'menus.finance',
				path: 'finance',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.financeMenu.repayment',
						path: 'repayment',
						component: (resolve) => require(['@/views/finance/Repayment'], resolve)
					},
					{
						name: 'menus.financeMenu.repaymentEntrty',
						path: 'repaymentEntrty',
						component: (resolve) => require(['@/views/finance/RepaymentEntrty'], resolve)
					},
					{
						name: 'menus.financeMenu.repaymentReview',
						path: 'repaymentReview',
						component: (resolve) => require(['@/views/finance/RepaymentReview'], resolve)
					},
					{
						name: 'menus.financeMenu.reviewDetails',
						path: 'reviewDetails',
						component: (resolve) => require(['@/views/finance/reviewDetails'], resolve)
					},
					{
						name: 'menus.financeMenu.upfrontfees',
						path: 'upfrontfees',
						component: (resolve) => require(['@/views/finance/UpfrontFees'], resolve)
					},
					{
						name: 'menus.financeMenu.overpayment',
						path: 'overpayment',
						component: (resolve) => require(['@/views/finance/Overpayment'], resolve)
					}
				]
			},
			{
				name: 'menus.collections',
				path: 'collections',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.collectionsMenu.loansDisbursed',
						path: 'loansDisbursed',
						component: (resolve) => require(['@/views/collectionsManagement/loansDisbursed'], resolve)
					},
					{
						name: 'menus.collectionsMenu.overdue',
						path: 'overdue',
						component: (resolve) => require(['@/views/collectionsManagement/overdue'], resolve)
					}
				]
			},
			{
				name: 'menus.accountChecking',
				path: 'accountChecking',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.accountCheckingMenu.mpesaAccountChecking',
						path: 'mpesaAccountChecking',
						component: (resolve) => require(['@/views/accountCheckingManagement/mpesaAccountChecking'], resolve)
					},
					{
						name: 'menus.accountCheckingMenu.bankAccountChecking',
						path: 'bankAccountChecking',
						component: (resolve) => require(['@/views/accountCheckingManagement/bankAccountChecking'], resolve)
					}
				]
			},
			{
				name: 'menus.insuranceRenewal',
				path: 'insuranceRenewal',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.insuranceRenewalMenu.renewal',
						path: 'renewal',
						component: (resolve) => require(['@/views/insuranceRenewalManagement/renewal'], resolve)
					},
					{
						name: 'menus.insuranceRenewalMenu.renewalDetail',
						path: 'renewalDetail',
						component: (resolve) => require(['@/views/insuranceRenewalManagement/renewalDetail'], resolve)
					},
					{
						name: 'menus.insuranceRenewalMenu.renewalList',
						path: 'renewalList',
						component: (resolve) => require(['@/views/insuranceRenewalManagement/renewalList'], resolve)
					},
					{
						name: 'menus.insuranceRenewalMenu.renewalReview',
						path: 'renewalReview',
						component: (resolve) => require(['@/views/insuranceRenewalManagement/renewalReview'], resolve)
					},
					{
						name: 'menus.insuranceRenewalMenu.renewalDisbursed',
						path: 'renewalDisbursed',
						component: (resolve) => require(['@/views/insuranceRenewalManagement/renewalDisbursed'], resolve)
					},
					{
						name: 'menus.insuranceRenewalMenu.renewalPending',
						path: 'renewalPending',
						component: (resolve) => require(['@/views/insuranceRenewalManagement/renewalPending'], resolve)
					},
					{
						// Disburse Now
						name: 'menus.insuranceRenewalMenu.disburseNow',
						path: 'disburseNow',
						component: () => import('@/views/loanData/disburse-now.vue')
					},
					{
						// Record Disbursement Entries
						name: 'menus.insuranceRenewalMenu.recordDisbursementEntries',
						path: 'recordDisbursementEntries',
						component: () => import('@/views/loanData/record-disbursement-entries.vue')
					},
					{
						// IPF - Disbursement Records
						name: 'menus.insuranceRenewalMenu.ipfDisbursementRecords',
						path: 'ipfDisbursementRecords',
						component: () => import('@/views/loanData/disbursement-records.vue')
					}
				]
			},
			{
				name: 'menus.emergencyLoan',
				path: 'emergencyLoan',
				component: (resolve) => require(['@/components/RouterIndex'], resolve),
				children: [
					{
						name: 'menus.emergencyLoanMenu.clientList',
						path: 'clientList',
						component: (resolve) => require(['@/views/emergencyLoan/clientList'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.applyInfo',
						path: 'applyInfo',
						component: (resolve) => require(['@/views/emergencyLoan/applyInfo'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.loanList',
						path: 'loanList',
						component: (resolve) => require(['@/views/emergencyLoan/loanList'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.loanInfo',
						path: 'loanInfo',
						component: (resolve) => require(['@/views/emergencyLoan/loanInfo'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.dataReview',
						path: 'dataReview',
						component: (resolve) => require(['@/views/emergencyLoan/dataReview'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.loanReview',
						path: 'loanReview',
						component: (resolve) => require(['@/views/emergencyLoan/loanReview'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.pendingList',
						path: 'pendingList',
						component: (resolve) => require(['@/views/emergencyLoan/pendingList'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.disbursed',
						path: 'disbursed',
						component: (resolve) => require(['@/views/emergencyLoan/disbursed'], resolve)
					},
					{
						name: 'menus.emergencyLoanMenu.detail',
						path: 'detail',
						component: (resolve) => require(['@/views/emergencyLoan/detail'], resolve)
					},
					{
						// Disburse Now
						name: 'menus.emergencyLoanMenu.disburseNow',
						path: 'disburseNow',
						component: () => import('@/views/loanData/disburse-now.vue')
					},
					{
						// Record Disbursement Entries
						name: 'menus.emergencyLoanMenu.recordDisbursementEntries',
						path: 'recordDisbursementEntries',
						component: () => import('@/views/loanData/record-disbursement-entries.vue')
					},
					{
						// Loan Application - Disbursement Records
						name: 'menus.emergencyLoanMenu.loanApplicationDisbursementRecords',
						path: 'loanApplicationDisbursementRecords',
						component: () => import('@/views/loanData/disbursement-records.vue')
					},
				]
			},
			{
				name: 'menus.dictionary',
				path: 'dictionarylist',
				component: (resolve) => require(['@/views/dictionary/list'], resolve)
			},
			{
				name: 'menus.dictionaryDetail',
				path: 'dictionarydetail',
				component: (resolve) => require(['@/views/dictionary/detail'], resolve)
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: (resolve) => require(['@/views/login'], resolve)
	},
	{
		path: '*',
		component: (resolve) => require(['@/components/exception/404'], resolve)
	},
	{
		path: '/',
		redirect: '/login'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.NODE_ENV === 'development' ? `/bccms` : `/`,
	routes: routes
})
router.beforeEach((to, from, next) => {
	if (to.path === '/login') {
		next()
		return
	}
	let token = sessionStorage.getItem('token')
	if (!token) {
		next({ path: '/login' })
	} else {
		next()
	}
})

export default router
