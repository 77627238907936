export default {
	namespaced: true,
	state: {
		uploadState: false,
		authObj: {},
		loadingText: '',
		canvasCustomerInfo: {},
		fromPath: ''
	},
	mutations: {
		setUploadState(state, data, text) {
			state.uploadState = data
			state.loadingText = text || ''
		},
		setCanvasCustomerInfo(state, data) {
			state.canvasCustomerInfo = data
		},
		setFromPath(state, data) {
			state.fromPath = data
		},
		setAuthObj(state, data) {
			state.authObj = data
		}
	}
}
