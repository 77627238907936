<template>
	<div class="svg-wrap">
		<svg class="svg-icon" aria-hidden="true">
			<use :xlink:href="iconName" />
		</svg>
		<slot name="bottomLabel"></slot>
	</div>
</template>

<script>
import '@/assets/iconfont'
export default {
	name: 'icon-svg',
	props: {
		iconClass: {
			type: String,
			required: true
		}
	},
	computed: {
		iconName: function () {
			return `#icon-${this.iconClass}`
		}
	}
}
</script>

<style type="text/css" lang="less" scoped>
.svg-wrap {
	display: inline-block;
	padding-right: 10px;
	text-align: center;
}
.svg-icon {
	width: 2em;
	height: 2em;
	/*vertical-align: -0.15em;*/
	vertical-align: middle;
	fill: currentColor;
	overflow: hidden;
	cursor: pointer;
}
</style>
