const getters = {
	table: (state) => state.table,
	navPermissions: (state) => state.navMenu.navPermissions,
	setting: (state) => state.setting,
	historyTabs: (state) => state.tabs.historyTabs,
	activeTab: (state) => state.tabs.activeTab,
	user: (state) => state.user,
	callCenter: (state) => state.callCenter,
	enumerate: (state) => state.enumerate,
	permission: (state) => state.permission,
	bankCode: (state) => state.bankCode,
	pageState: (state) => state.pageState
}
export default getters
