<template>
	<div>
		<div v-if="isPreviewFile">
			<div class="preview-file-box" v-for="(filePath, index) in uploadFilePathList" :key="index">
				<div v-if="filePath && ['pdf'].includes(getSuffix(filePath))">
					<a :href="$Util.getObsImageUrl($store.state.pageState.authObj, filePath)" class="file_name" target="_blank">
						{{ !$Util.isEmpty(filePath) ? filePath.split('/').at(-1) : '' }}
					</a>
					<iframe :src="$Util.getObsImageUrl($store.state.pageState.authObj, filePath)" frameborder="0" class="iframe-box"> </iframe>
					<a-popconfirm v-if="isUploadFileBtn" :title="$t('operate.sureDel')" ok-text="Yes" cancel-text="No" @confirm="deleteFile(index)">
						<a-icon type="close-circle" theme="filled" />
					</a-popconfirm>
				</div>
				<div v-else-if="filePath && ['png', 'jpg', 'jpeg', 'gif'].includes(getSuffix(filePath))" class="img-box">
					<img v-if="useViewer" :src="$Util.getObsImageUrl($store.state.pageState.authObj, filePath)" v-viewer alt="" class="img_pic" />
					<img v-else :src="$Util.getObsImageUrl($store.state.pageState.authObj, filePath)" alt="" class="img_pic" />
					<a-popconfirm v-if="isUploadFileBtn" :title="$t('operate.sureDel')" ok-text="Yes" cancel-text="No" @confirm="deleteFile(index)">
						<a-icon type="close-circle" theme="filled" />
					</a-popconfirm>
				</div>
				<div v-else>
					<a v-if="filePath" :href="$Util.getObsImageUrl($store.state.pageState.authObj, filePath)" class="file_name" target="_blank">
						{{ !$Util.isEmpty(filePath) ? filePath.split('/').at(-1) : '' }}
					</a>
					<a-popconfirm v-if="isUploadFileBtn && filePath" :title="$t('operate.sureDel')" ok-text="Yes" cancel-text="No" @confirm="deleteFile(index)">
						<a-icon type="close-circle" theme="filled" />
					</a-popconfirm>
				</div>
			</div>
		</div>
		<div v-if="isUploadFileBtn" style="margin: 10px 0">
			<a-button type="primary">
				<input type="file" ref="file" class="file" @change="UplaodFile" />
				<a-icon type="upload"></a-icon>
				{{ $t(`page.${uploadFile}`) }}
			</a-button>
			<slot name="btn"></slot>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { UploadObs } from '@/utils/UploadObs'
export default {
	props: {
		uploadFilePath: {
			type: null,
			required: true
		},
		folderPath: {
			type: String,
			default: 'common'
		},
		uploadFile: {
			type: String,
			default: 'uploadFile'
		},
		isUploadFileBtn: {
			type: Boolean,
			default: true
		},
		isPreviewFile: {
			type: Boolean,
			default: true
		},
		dataObject: {
			type: Object,
			default: () => {
				return {
					id: '',
					filename: '',
					note: '',
					file: ''
				}
			}
		},
		isImgFile: {
			type: Boolean,
			default: false
		},
		callbackFun: {
			type: Function,
			default: null
		},
		format: {
			type: String,
			default: ''
		},
		listLength: {
			type: Number,
			default: 20
		},
		// 是否使用viewer放大图片
		useViewer: {
			type: Boolean,
			default: true
		},
	},
	computed: {
		...mapGetters(['pageState']),
		dataType() {
			if (this.uploadFilePath) {
				return typeof this.uploadFilePath
			}
			return 'string'
		},
		uploadFilePathList() {
			return this.dataType === 'string' ? [this.uploadFilePath] : this.uploadFilePath
		}
	},
	methods: {
		getSuffix(val) {
			return !this.$Util.isEmpty(val) ? val.split('/').at(-1).split('.').pop() : ''
		},
		UplaodFile() {
			if (!this.pageState.uploadState) {
				if (this.$refs.file.files.length == 0) {
					return false
				}
				let file = this.$refs.file.files[0]
				if (this.isImgFile) {
					if (!file.type.match(/.jpg|.jpeg|.gif|.png|.bmp/i)) {
						this.$message.error('Please use the .jpg,.png,.jpeg etc. format.')
						return false
					}
					if (file.size / 1024 / 1024 > 10) {
						this.$message.error('File size cannot exceed 10M')
						return false
					}
				}
				if (this.format) {
					const matchVal = eval('/' + this.format + '/i')
					if (!file.type.match(matchVal)) {
						this.$message.error(`Please upload file in ${this.format} format.`)
						return false
					}
				}
				if (this.dataType !== 'string' && this.uploadFilePathList.length > this.listLength - 1) {
					this.$message.error(`Up to ${this.listLength} can be uploaded`)
					return false
				}
				let times = new Date().getTime()
				let newName = times + '.' + file.name
				let newFile = new File([file], newName, { type: file.type })
				this.$store.commit('pageState/setUploadState', true)
				console.log(this.pageState.authObj)
				UploadObs(this.pageState.authObj, newFile, newName, this.folderPath)
					.then(() => {
						let folderPathName = !this.$Util.isEmpty(this.folderPath) ? this.folderPath : 'common'
						let path = this.pageState.authObj.folderName + '/' + folderPathName + '/' + newName
						this.dataType === 'string'
							? this.$emit('update:uploadFilePath', path)
							: this.isPreviewFile
							? this.uploadFilePath.push(path)
							: this.uploadFilePath.push({ ...this.dataObject, ...{ file: path } })
						if (this.callbackFun) {
							this.callbackFun(path)
						}
					})
					.catch((err) => {
						this.$error({ content: err.message })
					})
					.finally(() => {
						this.$refs.file.value = ''
						this.$store.commit('pageState/setUploadState', false)
					})
			}
		},
		deleteFile(index) {
			this.dataType === 'string'
				? this.uploadFilePathList.splice(index, 1) && this.$emit('update:uploadFilePath', '')
				: this.uploadFilePath.splice(index, 1)
		},
		updateUploadFilePathList() {
			this.dataType === 'string'
				? this.uploadFilePathList.splice(0, this.uploadFilePath.length) && this.$emit('update:uploadFilePath', '')
				: this.uploadFilePath.splice(0, this.uploadFilePath.length)
		}
	}
}
</script>
<style lang="less">
.preview-file-box {
	position: relative;
	.iframe-box {
		width: 100%;
		height: 500px;
		margin-bottom: 10px;
	}
	.file_name {
		margin: 15px 0;
		font-size: 14px;
	}
	.anticon-close-circle {
		position: absolute;
		top: 15px;
		right: 15px;
		font-size: 28px;
		cursor: pointer;
	}
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
</style>
