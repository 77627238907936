export const salayBandEnum = [
	{ label: '0-40K', value: '0-40K' },
	{ label: '40-50K', value: '40K-50K' },
	{ label: '50-70K', value: '50K-70K' },
	{ label: '70-100K', value: '70K-100K' },
	{ label: '100-200K', value: '100K-200K' },
	{ label: '200K-Above', value: '200K-ABOVE' }
]
export const employmentStatusEnum = [
	{ label: 'Public Sector', value: 'PUBLIC SECTOR' },
	{ label: 'Private Sector', value: 'PRIVATE SECTOR' },
	{ label: 'Business', value: 'BUSINESS' },
	{ label: 'Unemployment', value: 'UNEMPLOYMENT' }
]
export const noOfYearsEnum = [
	{ label: 'Below 1', value: 'BELOW 1' },
	{ label: '1-2 Years', value: '1-2 YEARS' },
	{ label: '3-4 Years', value: '3-4 YEARS' },
	{ label: '4-5 Years', value: '4-5 YEARS' },
	{ label: '5-7 Years', value: '5-7 YEARS' },
	{ label: 'Above 7 Years', value: 'ABOVE 7 YEARS' }
]
export const vehicleUseEnum = [
	{ text: 'Non disclosed use', value: 'NON DISCLOSED USE' },
	{ text: 'PSV', value: 'PSV' },
	{ text: 'Commercial/Tour company', value: 'COMMERCIAL/TOUR COMPANY' },
	{ text: 'Uber/Taxi', value: 'UBER/TAXI' },
	{ text: 'Personal use', value: 'PERSONAL USE' }
]
