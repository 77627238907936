import { getBankList } from '@/api/loan'

export default {
	namespaced: true,
	state: {
		salesmanList: [],
		oriSalesmanList: [],
		// 收款人类型
		payeeType: [
			{ label: 'Individual', value: 0 },
			{ label: 'Business', value: 1 }
		],
		// 银行列表
		bankList: []
	},
	mutations: {
		setSalesmanList: (state, data) => {
			state.oriSalesmanList = data

			let salesmanList = []
			for (let i of data) {
				salesmanList.push({
					name: i.username,
					label: `${i.username}(${i.displayName ? i.displayName : '-'})`,
					value: i.id
				})
			}

			state.salesmanList = salesmanList
		},
		setBankList: (state, value) => {
			state.bankList = value
		}
	},
	actions: {
		// 初始化枚举值
		async initEnums({ commit, dispatch }) {
			const [bankListRes] = await Promise.all([getBankList()])
			commit('setBankList', bankListRes)
		},
	}
}
