<template>
	<div class="tabs-wrap">
		<a-affix :offset-top="0.1" style="background-color: #fff">
			<a-tabs
				:hide-add="true"
				:tabBarGutter="4"
				size="small"
				animated
				type="editable-card"
				:activeKey="activeTab.fullPath"
				@tabClick="tabClick"
				@edit="closeTab"
			>
				<a-tab-pane :key="item.fullPath" v-for="item in historyTabs" :closable="historyTabs.length > 1">
					<span slot="tab" v-t.preserve="item.name"></span>
				</a-tab-pane>
			</a-tabs>
		</a-affix>
		<keep-alive v-if="multipage">
			<slot name="router-view"></slot>
		</keep-alive>
		<slot name="router-view" v-else></slot>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			multipage: false
		}
	},
	computed: {
		...mapGetters(['historyTabs', 'activeTab'])
	},
	methods: {
		tabClick(key) {
			if (this.activeTab.fullPath !== key) {
				this.$router
					.push(key)
					.then((e) => {
						this.$store.commit('tabs/setActiveTab', key)
					})
					.catch((e) => {
						console.error(e)
					})
			}
		},
		closeTab(targetKey, action) {
			if (action === 'remove') {
				this.$store.commit('tabs/delTab', targetKey)
				let lg = this.historyTabs.length
				if (lg > 0) {
					let key = this.historyTabs[lg - 1].fullPath
					this.tabClick(key)
				}
			}
		}
	}
}
</script>

<style lang="less">
.tabs-wrap {
	background-color: #fff;
	.ant-tabs-bar {
		margin: 0;
	}
	.ant-affix {
		background-color: #fff;
	}
}
</style>
