var hmacsha1 = require('hmacsha1')

export const getObsImageUrl = (obj, path_url) => {
	if (path_url) {
		path_url = encodeURIComponent(path_url)
		let url = 'https://' + obj.bucketName + '.' + obj.endpoint + '/' + path_url + '?'
		let time = new Date(obj.credential.expires_at).getTime()
		let sk = obj.credential.secret
		let ak = obj.credential.access
		let token = obj.credential.securitytoken
		let CanonicalizedHeaders = '/' + obj.bucketName + '/' + path_url
		let CanonicalizedResource = '?x-obs-security-token=' + token
		let expiresValue = parseInt(time / 1000) + 3600 * 8
		let HTTPVerb = 'GET'
		let ContentMD5 = ''
		let ContentType = ''
		let stringToSign = HTTPVerb + '\n' + ContentMD5 + '\n' + ContentType + '\n' + expiresValue + '\n' + CanonicalizedHeaders + CanonicalizedResource
		let signature = encodeURIComponent(hmacsha1(sk, stringToSign))
		url = url + 'AccessKeyId=' + ak + '&Expires=' + expiresValue + '&Signature=' + signature + '&x-obs-security-token=' + token
		return url
	} else {
		return ''
	}
}

export const isEmpty = (obj) => {
	if (typeof obj === 'undefined' || obj === null || obj === '') {
		return true
	} else {
		return false
	}
}
export default { getObsImageUrl, isEmpty }
