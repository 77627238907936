export const loanType = [
	{
		name: 'Logbook Loan',
		value: 0,
		interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08],
		installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
		descriptionsItem: [
			'loanProcessingFee',
			'creditLifeInsuranceFee',
			'maintenanceFeeInstallment',
			'gpsInstallAmount',
			'gpsMonthAmount',
			'mortgageRegistrationAmount',
			'applicationFee',
			'exciseDuty'
		],
		defaultsValue: {
			interestRate: 0.08,
			installmentsNumbers: 3,
			applicationFee: 3800,
			maintenanceFeeInstallment: 600
		},
		defScale: {
			loanProcessingFee: '3%',
			creditLifeInsuranceFee: '3%',
			exciseDuty: '15%'
		},
		aliasItem: [],
		prefixType: 'LBC '
	},
	{
		name: 'Asset Finance',
		value: 1,
		interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08],
		installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
		descriptionsItem: [
			'loanProcessingFee',
			'creditLifeInsuranceFee',
			'maintenanceFee',
			'gpsInstallAmount',
			'gpsMonthAmount',
			'mortgageRegistrationAmount',
			'applicationFee',
			'exciseDuty'
		],
		defaultsValue: {
			interestRate: 0.08,
			installmentsNumbers: 3,
			applicationFee: 4800
		},
		defScale: {
			loanProcessingFee: '5%',
			creditLifeInsuranceFee: '4%',
			maintenanceFeeInstallment: '1%',
			exciseDuty: '15%'
		},
		aliasItem: [],
		prefixType: 'ABC '
	},
	{
		name: 'Pesa Speedy',
		value: 2,
		interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06],
		installmentsOptions: Array.from({ length: 12 }, (_, i) => 1 + i),
		descriptionsItem: [
			'loanProcessingFee',
			'creditLifeInsuranceFee',
			'maintenanceFeeInstallment',
			'gpsInstallAmount',
			'gpsMonthAmount',
			'mortgageRegistrationAmount',
			'applicationFee',
			'exciseDuty'
		],
		defaultsValue: {
			interestRate: 0.06,
			installmentsNumbers: 3,
			applicationFee: 3800,
			maintenanceFeeInstallment: 600
		},
		defScale: {
			loanProcessingFee: '3%',
			creditLifeInsuranceFee: '3%',
			exciseDuty: '15%'
		},
		aliasItem: [],
		prefixType: 'PBC '
	},
	{
		name: 'Wezesha Loan',
		value: 3,
		interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
		installmentsOptions: [1],
		descriptionsItem: ['loanProcessingFee', 'mortgageRegistrationAmount', 'applicationFee', 'exciseDuty'],
		defaultsValue: {
			interestRate: 0.1,
			installmentsNumbers: 1,
			applicationFee: 1600
		},
		defScale: {
			loanProcessingFee: '3%',
			exciseDuty: '15%'
		},
		aliasItem: ['applicationFee'],
		prefixType: 'WBC '
	},
	{
		name: 'Staff Loan',
		value: 4,
		interestRateOptions: [0.0108, 0.010833],
		installmentsOptions: Array.from({ length: 48 }, (_, i) => 1 + i),
		descriptionsItem: [
			'loanProcessingFee',
			'creditLifeInsuranceFee',
			'gpsInstallAmount',
			'gpsMonthAmount',
			'mortgageRegistrationAmount',
			'applicationFee',
			'exciseDuty'
		],
		defaultsValue: {
			interestRate: 0.0108,
			installmentsNumbers: 36,
			applicationFee: 3300
		},
		defScale: {
			loanProcessingFee: '1%',
			creditLifeInsuranceFee: '3%',
			exciseDuty: '15%'
		},
		aliasItem: ['applicationFee'],
		prefixType: 'SBC '
	},
	{},
	{},
	{
		name: 'IPF-BIMA',
		value: 7,
		interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
		installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
		descriptionsItem: ['loanProcessingFee', 'applicationFee', 'exciseDuty'],
		defaultsValue: {
			interestRate: 0.05,
			installmentsNumbers: 10,
			applicationFee: 2000
		},
		defScale: {
			loanProcessingFee: '7%',
			exciseDuty: '15%'
		},
		aliasItem: ['applicationFee'],
		prefixType: 'IBC '
	}
]
