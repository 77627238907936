/*
 * @Author: your name
 * @Date: 2019-11-30 17:11:03
 * @LastEditTime: 2019-12-03 15:43:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \collection\src\api\user.js
 */
import { _axios } from '@/plugins/axios'

// 检查用户名是否存在
export const apiCheckExist = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/user/checkExist?username=${dataJson.username}`,
		method: method
	})
}

// 检查用户密码是否正确
export const apiCheckPassword = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/user/checkPassword?username=${encodeURIComponent(dataJson.username)}&password=${encodeURIComponent(dataJson.password)}`,
		method: method
	})
}

// 重置密码
export const apiResetPassword = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/user/resetPassword`,
		method: method,
		data: dataJson
	})
}

// 分页查出用户列表接口
export const _tableUser = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/queryUserList`,
		method: method,
		data: dataJson
	})
}

export const _addUser = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/addUser`,
		method: method,
		data: dataJson
	})
}

export const _modUser = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/modUser`,
		method: method,
		data: dataJson
	})
}

export const _delUser = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/delUser`,
		method: method,
		data: dataJson
	})
}

// 使用账号登录
export const _loginByuserAccount = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/login/auth`,
		method: method,
		data: dataJson
	})
}

export const apiAllSalesmanList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/application/salesmanList`,
		method: method,
		data: dataJson
	})
}

// 修改账号密码
export const setUserPassword = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/modify_password`,
		method: method,
		data: dataJson
	})
}

// 重置账号密码
export const resetUserPassword = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/reset_password`,
		method: method,
		data: dataJson
	})
}

// 查询用户权限
export const apiQueryPermissionByListId = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/queryPermission`,
		method: method,
		data: dataJson
	})
}
// 查询用户权限
export const apiQueryPermission = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/queryPermission`,
		method: method,
		data: dataJson
	})
}
// 查询用户权限
export const apiQueryAllPermissionTree = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/role/permissionlist`,
		method: method,
		data: dataJson
	})
}

// 查询用户基本信息
export const apiQueryUserBylistId = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/queryUserBylistId`,
		method: method,
		data: dataJson
	})
}

// 查询用户基本信息
export const apiGetProductList = (dataJson, method = 'get') => {
	return _axios({
		url: `/api/user/getAllProduct`,
		method: method,
		data: dataJson
	})
}

// 获取所有team_name
export const apiQueryAllTeam = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/queryAllTeam`,
		method: method,
		data: dataJson
	})
}

/**
 * 查看催收员列表接口
 * @param dataJson<
 * "list_name":<雇员姓名>,
 * "team_id":<所属团队id>,
 * "list_id":<雇员id>,
 * # 取上面3个id的交集关系，为空则不做条件
 * "case_range":<负责的案件范围>,
 * "case_class":<负责的案件分类>,
 * "product":<产品名字>,
 * "attendance":<是否出席 0:不出席，1:出席>,
 * >
 * @param method
 * @returns {AxiosPromise}
 */
export const apiGetEmployeeDaily = (dataJson, method = 'get') => {
	return _axios({
		url: `/api/user/employee-daily`,
		method: method,
		data: dataJson
	})
}

// 查看催收员每日留案接口
/**
 * 查看催收员每日留案接口
 * @param dataJson <{
    "list_name":<雇员姓名>,
    "team_id":<所属团队id>,
    "list_id":<雇员id>,
    # 取上面3个id的交集关系
    "case_range":<负责的案件范围>,
    "data_date":<时间>
  }>
 * @param method
 * @returns {*}
 */
export const apiGetEmployeeHoldcaseDaily = (dataJson, method = 'get') => {
	return _axios({
		url: `/api/user/employee-holdcase-daily`,
		method: method,
		data: dataJson
	})
}

// 每日留单 删除历史留单
export const apiDeleteEmployeeHoldcaseDaily = (data) => {
	return _axios({
		url: `/api/user/delete-employee-holdcase-daily`,
		method: 'post',
		data
	})
}

export const apiUploadHoldcaseCsv = (formData, method = 'post') => {
	return _axios({
		url: `/api/user/handleUploadHoldcaseCsv`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		method: method,
		data: formData
	})
}

export const apiUploadEmployeeDailyCsv = (formData, method = 'post') => {
	return _axios({
		url: `/api/user/handleUploadEmployeeDailyCsv`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		method: method,
		data: formData
	})
}

export const apiUpdateEmployeeDaily = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/updateEmployeeDaily`,
		method: method,
		data: dataJson
	})
}

export const apiUpdateEmployeeDailyAttendance = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/updateEmployeeDailyAttendance`,
		method: method,
		data: dataJson
	})
}

export const apiGetCallRecordsByLoanId = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/get_call_records`,
		method: method,
		data: dataJson
	})
}

export const apiGetRepaymentRecord = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/get_repayment_record`,
		method: method,
		data: dataJson
	})
}

export const apiResetListIdPassword = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/user/reset-password`,
		method: method,
		data: dataJson
	})
}

// 催收员分组列表
export const apiTeamList = (data) => {
	return _axios({
		url: `/api/role/queryTeamList`,
		method: 'get',
		data
	})
}
// 催收员设置 创建分组
export const apiCreatTeam = (data) => {
	return _axios({
		url: `/api/role/createTeam`,
		method: 'post',
		data
	})
}
// 催收员设置 更新、删除分组
export const apiUpdateTeam = (data) => {
	return _axios({
		url: `/api/role/updateTeam`,
		method: 'post',
		data
	})
}

// 催收员设置 range列表
export const apiRangeList = (data) => {
	return _axios({
		url: `/api/role/queryRangeDetail`,
		method: 'get',
		data
	})
}
// 催收员设置 更新range
export const apiUpdateRange = (data) => {
	return _axios({
		url: `/api/role/updateRange`,
		method: 'post',
		data
	})
}

// 催收员设置 留单列表
export const apiHoldcaseList = (data) => {
	return _axios({
		url: `/api/myCase/get-holdcase-limit`,
		method: 'get',
		data
	})
}
// 催收员设置 更新留单
export const apiUpdateHoldcase = (data) => {
	return _axios({
		url: `/api/myCase/update-holdcase-limit`,
		method: 'post',
		data
	})
}

// 手动分案 - 获取释放案件信息
export const apiReleaseEmployee = (data) => {
	return _axios({
		url: `/api/user/get_employee_for_release`,
		method: 'get',
		data
	})
}

// 忘记密码-验证手机
export const apiCheckMobile = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/user/forgotPassword/checkMobile`,
		method: method,
		data: dataJson
	})
}

// 忘记密码-发送验证码
export const apiSendCode = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/user/forgotPassword/sendCode`,
		method: method,
		data: dataJson
	})
}

// 忘记密码-验证验证码
export const apiVerifyCode = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/user/forgotPassword/verifyCode`,
		method: method,
		data: dataJson
	})
}

// 忘记密码-重置密码
export const apiSetPassword = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/user/forgotPassword/setPassword`,
		method: method,
		data: dataJson
	})
}
