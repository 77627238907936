/*
 * @Author: your name
 * @Date: 2019-11-18 10:43:15
 * @LastEditTime: 2019-11-18 18:03:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \collection\src\store\modules\navMenu.js
 */

const menuData = []
const navMenu = {
  namespaced: true,
  state: {
    navPermissions: menuData,
    selectObj: {}
  },
  mutations: {
    setNavMenu: (state, model) => {
      state.navPermissions = model
    },
    set_navMenuTree: (state, model) => {
      state.navMenuTree = model
    }
  }
}

export default navMenu
