import * as ObsClient from 'esdk-obs-browserjs'
export const UploadObs = (obj, file, fileName, id) => {
	let sk = obj.credential.secret,
		ak = obj.credential.access,
		token = obj.credential.securitytoken,
		endpoint = obj.endpoint,
		bucketName = obj.bucketName,
		folderName = obj.folderName
	const obsObj = new ObsClient({
		access_key_id: ak,
		secret_access_key: sk,
		security_token: token,
		server: 'https://' + endpoint
	})
	let key = ''
	if (id) {
		key = folderName + '/' + id + '/' + fileName
	} else {
		key = folderName + '/common/' + fileName
	}
	return obsObj.putObject({
		Bucket: bucketName,
		Key: key,
		SourceFile: file
	})
}
