<template>
  <a-dropdown class="mib-select"
    :trigger="['click']"
    overlayClassName="mib-select__dropdown"
    v-model="visible"
  >
    <div class="mib-select__trigger" @click="handleTriggerClick">
      <!-- 暂时不需要使用输入框进行筛选 -->
      <a-input @click="e => e.preventDefault()">
      <!-- <a-input
        ref="inputBox"
        @click="e => e.preventDefault()"
        v-model="inputVal"
      > -->
        <a-icon slot="suffix" type="down" />
      </a-input>
      <div class="mib-select__tags">
        <a-tag
          v-for="(item, index) in checkedList"
          closable
          :key="item.value"
          @close="handleTagsClose(index)"
        >
          {{ item.label }}
        </a-tag>
      </div>
    </div>

    <a-menu slot="overlay">
      <a-menu-item v-if="optionLength === 0">
        <a-empty />
      </a-menu-item>
      <template v-else>
        <a-menu-item>
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            All
          </a-checkbox>
        </a-menu-item>
        <a-checkbox-group v-model="checkedList" @change="onChange">
          <a-menu-item v-for="(item, index) in showOptions" :key="index">
            <a-checkbox :value="item">
              {{ item.label }}
            </a-checkbox>
          </a-menu-item>
        </a-checkbox-group>
      </template>
    </a-menu>
  </a-dropdown>
</template>

<script>
export default {
  name: 'CheckboxSelect',
  model: {
    prop: 'pValue', // 默认值
    event: 'change',
  },
  props: ['options', 'pValue'],
  created () {
    this.initChecked()
  },
  data () {
    return {
      checkAll: false,
      indeterminate: false, // 全选形状
      checkedList: [],
      visible: false,
      inputVal: '',
    }
  },
  watch: {
    checkedList (val) {
      let res = ''
      if (this.checkAll) {
        res = 'all'
      } else {
        res = val.map(_ => _.value).join(',')
      }

      this.$emit('change', res)
    }
  },
  computed: {
    optionAll () {
      return this.showOptions.map(_ => _.value)
    },
    showOptions () {
      const { inputVal, options } = this
      if (!inputVal.trim().length) return options
      const lowerV = inputVal.toLocaleLowerCase()
      // 忽略大小写
      return options.filter(_ => _.label.toLocaleLowerCase().includes(lowerV))
    },
    optionLength () {
      return this.showOptions.length
    },
  },
  methods: {
    // 默认全选
    initChecked () {
      // this.onChange(this.showOptions.filter(opt => this.pValue.includes(opt.value)))
      this.onChange(this.showOptions) // 默认全选
    },
    onCheckAllChange (e) {
      const val = e.target.checked
      this.indeterminate = false
      this.checkAll = val
      this.checkedList = val ? this.showOptions : []
      this.visible = false
    },
    onChange (list) {
      const checkedLen = list.length
      this.checkAll = checkedLen === this.optionLength
      this.indeterminate = !!checkedLen && checkedLen < this.optionLength
      this.checkedList = list
    },
    handleTriggerClick () {
      // this.$refs['inputBox'].focus()
    },
    handleTagsClose (index) {
      let list = [...this.checkedList]
      list.splice(index, 1)
      this.onChange(list)
    }
  }
}
</script>

<style lang='less' scoped>
.mib-select {
  display: inline-block;
  outline: none;
  min-width: 120px;
  width: 200px;
  position: relative;
  cursor: pointer;
  &__tags {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 20px;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 30px;
    white-space: nowrap;
    .ant-tag {
      margin-right: 2px;
    }
  }
}
</style>
<style lang='less'>
.ant-dropdown.mib-select__dropdown {
  .ant-checkbox-wrapper, .ant-checkbox-group {
    width: 100%;
  }
  .ant-checkbox-group {
    li {
      padding: 5px 12px;
      &:hover {
        background-color: #e6f7ff;
      }
    }
  }
}
</style>
