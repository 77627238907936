/*
 * @Author: LPL
 * @Date: 2019-11-26 10:38:39
 * @LastEditTime: 2019-11-26 16:39:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioan\src\store\modules\setting.js
 */
const languages = [
	{
		country: 'CHN',
		locale: 'zh',
		text: '中文'
	},
	{
		country: 'USA',
		locale: 'en',
		text: 'American English'
	}
]
export default {
	namespaced: true,
	state: {
		sip: {
			address: process.env.VUE_APP_SIP_ADDRESS,
			wsAddress: process.env.VUE_APP_SIP_WS_ADDRESS,
			wssAddress: process.env.VUE_APP_SIP_WSS_ADDRESS
		},
		vueEnvStaging: process.env.VUE_APP_ENV !== 'production',
		languages: languages,
		curLanguage: {
			country: 'USA',
			locale: 'en',
			text: 'American English'
		},
		lang: 'en',
		isMobile: false,
		theme: 'dark',
		layout: 'side',
		systemName: 'Bees Credit',
		copyright: '2021 XGO 工作室出品',
		footerLinks: [
			{ link: 'https://pro.ant.design', name: 'Pro首页' },
			{ link: 'https://github.com/iczer/vue-antd-admin', icon: 'github' },
			{ link: 'https://ant.design', name: 'Ant Design' }
		],
		multipage: true,
		manualAllocProd: '' // 保存手动分案的app
	},
	mutations: {
		initcurLang(state) {
			let languages = state.languages
			let i = process.env.VUE_APP_I18N_LOCALE
			let index = languages.findIndex((k) => {
				return k.locale === i
			})
			state.curLanguage = state.languages[index]
		},
		setLang(state, index) {
			state.curLanguage = state.languages[index]
			let map = {
				zh: 'Chinese',
				en: 'English'
			}
			localStorage.lang = map[state.curLanguage.locale]
			// locale = state.curLanguage.locale
		},
		setDevice(state, isMobile) {
			state.isMobile = isMobile
		},
		setTheme(state, theme) {
			state.theme = theme
		},
		setLayout(state, layout) {
			state.layout = layout
		},
		setMultipage(state, multipage) {
			state.multipage = multipage
		},
		setManualAllocProd(state, data) {
			state.manualAllocProd = data
		}
	}
}
