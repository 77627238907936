<template>
	<div class="canvas-container" ref="imageWrapper">
		<div class="header">
			<img v-if="type === 'finance'" src="@/assets/canvas-header.png" class="logo-img" crossOrigin="anonymous" />
			<img v-else-if="type === 'collections'" src="@/assets/collections-header.png" class="logo-img" crossOrigin="anonymous" />
		</div>
		<div class="finance-info" v-if="type === 'finance'">
			<p class="title">LOAN STATEMENT</p>
			<a-row :gutter="50">
				<a-col :span="24">
					<a-descriptions bordered>
						<a-descriptions-item v-for="(item, idx) in pageState.canvasCustomerInfo.left" :label="item.name" :span="3" :key="idx">
							<span v-html="item.value"></span>
						</a-descriptions-item>
						<a-descriptions-item label="Date of Loan Statement:" :span="3">
							{{ new Date().toLocaleDateString().replaceAll('/', '-') }}
						</a-descriptions-item>
					</a-descriptions>
				</a-col>
				<a-col :span="24">
					<a-descriptions bordered class="two-row">
						<a-descriptions-item label="Loan Type" :span="3">
							<span>Asset Finance</span>
							<span v-for="(item, idx) in pageState.canvasCustomerInfo.loanInsuranceInfos" :key="idx">{{
								item.loanType ? $t(`table.applyInfo.financialLabel.loanMap.${item.loanType}`) : 'IPF ' + (idx + 1)
							}}</span>
						</a-descriptions-item>
						<a-descriptions-item label="Principal Amount:" :span="3">
							<span>{{ pageState.canvasCustomerInfo.right[0].value }}</span>
							<span v-for="(item, idx) in pageState.canvasCustomerInfo.loanInsuranceInfos" :key="idx">{{
								item.insuranceAmount ? item.insuranceAmount : item.amountPayable
							}}</span>
						</a-descriptions-item>
						<a-descriptions-item label="Interest Rate:" :span="3">
							<span>{{ pageState.canvasCustomerInfo.right[1].value }}</span>
							<span v-for="(item, idx) in pageState.canvasCustomerInfo.loanInsuranceInfos" :key="idx">{{
								(item.insuranceInterest ? item.insuranceInterest : (item.interestRate * 100).toFixed(1)) + '%'
							}}</span>
						</a-descriptions-item>
						<a-descriptions-item label="Vehicle:" :span="3">
							<div v-html="pageState.canvasCustomerInfo.right[2].value"></div>
						</a-descriptions-item>
						<a-descriptions-item label="Loan Period:" :span="3">
							<span>{{ pageState.canvasCustomerInfo.right[3].value }}</span>
							<span v-for="(item, idx) in pageState.canvasCustomerInfo.loanInsuranceInfos" :key="idx">{{
								item.insuranceInstallments ? item.insuranceInstallments : item.installmentsNumbers
							}}</span>
						</a-descriptions-item>
					</a-descriptions>
				</a-col>
			</a-row>
			<div v-for="(item, index) in splitCanvasDataSource" :key="index">
				<a-table class="canvas-table" :columns="columns" :data-source="item" bordered :pagination="false"> </a-table>
				<div style="height: 100px" v-if="!(index == splitCanvasDataSource.length - 1)"></div>
			</div>
		</div>
		<div class="collections-info" v-else-if="type === 'collections'">
			<p class="title">TINY BEES CREDIT KENYA LOAN STATEMENT</p>
			<a-descriptions bordered>
				<a-descriptions-item v-for="(item, idx) in collectionsInfo" :label="item.label" :span="3" :key="idx">
					<span v-if="item.isAmount">Ksh <a-statistic class="amount-statistic" :precision="2" :value="item.value" /></span>
					<span v-else v-html="item.value"></span>
				</a-descriptions-item>
				<a-descriptions-item>
					<span>Loan Statement as at {{ moment(printDateStr).format('Do MMMM YYYY') }}</span>
				</a-descriptions-item>
			</a-descriptions>
			<a-table class="collections-table" :columns="collectionsColumns" :data-source="collectionsDataSource" bordered :pagination="false"> </a-table>
			<a-row>
				<a-col :span="4" style="padding: 8px 16px; color: #000"> {{ moment(printDateStr).format('DD-MM-YY') }} </a-col>
				<a-col :span="4" class="balance" offset="1"> Balance </a-col>
				<a-col :span="10" class="balance" offset="5">
					<a-row>
						<a-col :span="8" style="text-align: left"><a-statistic :precision="2" :value="totalDebit" /></a-col>
						<a-col :span="8" style="text-align: center"><a-statistic :precision="2" :value="totalCredit" /></a-col>
						<a-col :span="8" style="text-align: right"><a-statistic :precision="2" :value="totalBalance" /></a-col>
					</a-row>
				</a-col>
			</a-row>
			<a-statistic class="balance-total" :precision="2" :value="amountDue">
				<template #prefix> Amount due Kes. </template>
			</a-statistic>
			<a-row class="bottom-addr">
				<a-col :span="10">
					<a-icon type="home" class="home" />
					<p v-if="pageState.canvasCustomerInfo.cityCode === 'Nairobi'">
						28 Margaret Wambui Kenyatta Road,<br />Mugumo Road,Lavington, Nairobi,Kenya.
					</p>
					<p v-if="pageState.canvasCustomerInfo.cityCode === 'Mombasa'">
						Moi Avenue Road, Jubilee Arcade Building,<br />
						Ground Floor, Mombasa, Kenya.
					</p>
				</a-col>
				<a-col :span="8" offset="6" class="telphone">
					<span class="arrow"></span>
					<span class="tel"><a-icon type="phone" class="phone" />+254 110 123 123</span>
					<span class="arrow-bg"></span>
					<span class="arrow"></span>
				</a-col>
			</a-row>
			<div class="block-div"
				><span class="block block1"></span><span class="block block2"></span><span class="block block3"></span><span class="block block4"></span
			></div>
		</div>
		<div v-else></div>
		<div class="footer">
			<img v-if="type === 'finance'" src="@/assets/canvas-footer.png" class="logo-img" crossOrigin="anonymous" />
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { apiStatement } from '@/api/loan'
export default {
	props: ['type', 'canvasDataSource', 'loanStatus'],
	data() {
		return {
			collectionsDataSource: [],
			columns: [
				{
					title: '',
					dataIndex: 'name',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				},
				{
					title: 'Principal',
					dataIndex: 'principal',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				},
				{
					title: 'Monthly Trancking Fee',
					dataIndex: 'trackingFee',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				},
				{
					title: 'Loan Maintenance Fee',
					dataIndex: 'maintenanceFee',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				},
				{
					title: 'Other Charges',
					dataIndex: 'otherFee',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				},
				{
					title: 'Penalty',
					dataIndex: 'penalty',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				},
				{
					title: 'Interest',
					dataIndex: 'interest',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				},
				{
					title: 'Total',
					dataIndex: 'total',
					align: 'center',
					customRender: (v, o) => {
						if (o.name.indexOf('Balance') !== -1) {
							return <span class="balance">{v}</span>
						} else {
							return <span>{v}</span>
						}
					}
				}
			],
			collectionsInfo: [],
			printDateStr: '',
			amountDue: 0,
			totalDebit: 0,
			totalCredit: 0,
			totalBalance: 0,
			splitCanvasDataSource: []
		}
	},
	computed: {
		...mapGetters(['pageState']),
		collectionsColumns() {
			return [
				{
					title: 'Transaction Date',
					dataIndex: 'transactionDate',
					align: 'left',
					width: 180,
					customRender: (v, o) => {
						return <span>{this.moment(v).format('DD-MM-YY')}</span>
					}
				},
				{
					title: ' Description',
					dataIndex: 'description',
					align: 'left'
				},
				{
					title: 'Ref.',
					dataIndex: 'ref',
					align: 'left'
				},
				{
					title: 'Debit',
					dataIndex: 'debit',
					align: 'left',
					customRender: (v, o) => {
						return v ? <a-statistic precision={2} value={v} /> : <span>-</span>
					}
				},
				{
					title: 'Credit',
					dataIndex: 'credit',
					align: 'center',
					customRender: (v, o) => {
						return v ? <a-statistic precision={2} value={v} /> : <span>-</span>
					}
				},
				{
					title: 'Balance',
					dataIndex: 'balance',
					align: 'right',
					customRender: (v, o) => {
						return v ? <a-statistic precision={2} value={v} /> : <span>-</span>
					}
				}
			]
		}
	},
	created() {
		this.$nextTick(() => {
			this.splitCanvasDataSource = this.getSplitCanvasDataSource(this.canvasDataSource.slice(10), 24)
			this.splitCanvasDataSource.unshift(this.canvasDataSource.slice(0, 10))
			// if (this.loanStatus < 1005) {
			apiStatement(this.$route.query.id).then((res) => {
				this.collectionsInfo = [
					{
						label: 'Name:',
						value: res.fullName
					},
					{
						label: 'ID:',
						value: res.identityCard
					},
					{
						label: 'Phone number:',
						value: res.mobile
					},
					{
						label: 'Vehicle:',
						value: res.vehicles
					},
					{
						label: 'Principal Amount:',
						value: res.principalAmount,
						isAmount: true
					},
					{
						label: 'Interest:',
						value: (res.interest * 100).toFixed(2) + '% Flat Rate PM'
					},
					{
						label: 'Loan Period:',
						value: res.period + ' Months'
					},
					{
						label: 'First Month Installment:',
						value: res.firstMonthRepay,
						isAmount: true
					},
					{
						label: 'Other Month Installment:',
						value: res.otherMonthRepay,
						isAmount: true
					}
				]
				this.printDateStr = res.printDateStr
				this.amountDue = res.currentOutstanding
				this.collectionsDataSource = res.statements
				this.totalDebit = res.statements.reduce((pre, cur) => {
					return pre + cur.debit
				}, 0)
				this.totalCredit = res.statements.reduce((pre, cur) => {
					return pre + cur.credit
				}, 0)
				this.totalBalance = res.statements[res.statements.length - 1].balance
			})
			// }
		})
	},
	methods: {
		moment: moment,
		onExport() {
			const clientWidth = this.$refs.imageWrapper.offsetWidth
			const clientHeight = this.$refs.imageWrapper.offsetHeight
			const kh = [clientWidth, clientHeight]
			html2canvas(this.$refs.imageWrapper, {
				useCORS: true,
				logging: true
			}).then((canvas) => {
				let contentWidth = canvas.width
				let contentHeight = canvas.height
				let pageHeight = (contentWidth / 592.28) * 841.89
				let leftHeight = contentHeight
				let position = 0
				let imgWidth = 595.28
				let imgHeight = (592.28 / contentWidth) * contentHeight
				let pageData = canvas.toDataURL('image/jpeg', 1.0)
				let PDF = new JsPDF('', 'pt', 'a4')
				if (leftHeight < pageHeight) {
					PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
				} else {
					while (leftHeight > 0) {
						PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
						leftHeight -= pageHeight
						position -= 841.89
						if (leftHeight > 0) {
							PDF.addPage()
						}
					}
				}
				PDF.save(`loan statement for ${this.type}.pdf`)
				// const dataURL = canvas.toDataURL('image/png')
				// this.download(dataURL, kh)
			})
		},
		getUrlBase64(url, kh) {
			return new Promise((resolve) => {
				let canvas = document.createElement('canvas')
				const ctx = canvas.getContext('2d')
				const img = new Image()
				img.crossOrigin = 'Anonymous' // 允许跨域
				img.src = url
				img.onload = () => {
					canvas.height = kh[1]
					canvas.width = kh[0]
					ctx.drawImage(img, 0, 0, kh[0], kh[1])
					const dataURL = canvas.toDataURL('image/png')
					canvas = null
					resolve(dataURL)
				}
			})
		},
		download(imgUrl, kh) {
			this.getUrlBase64(imgUrl, kh).then((base64) => {
				// 下载
				const link = document.createElement('a')
				link.href = base64
				link.download = `loan statement for ${this.type}.png`
				link.click()
				// 新页面打开
				let arr = base64.split(',') //去掉base64格式图片的头部
				let bstr = atob(arr[1]) //atob()方法将数据解码
				let leng = bstr.length
				let u8arr = new Uint8Array(leng)
				while (leng--) {
					u8arr[leng] = bstr.charCodeAt(leng) //返回指定位置的字符的 Unicode 编码
				}
				window.open(window.URL.createObjectURL(new File([u8arr], `loan statement for ${this.type}.png`, { type: 'image/png' })))
			})
		},
		getSplitCanvasDataSource(arr, size) {
			const arrNum = Math.ceil(arr.length / size, 10) // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
			let index = 0 // 定义初始索引
			let resIndex = 0 // 用来保存每次拆分的长度
			const result = []
			while (index < arrNum) {
				result[index] = arr.slice(resIndex, size + resIndex)
				resIndex += size
				index++
			}
			return result
		}
	}
}
</script>
<style lang="less">
.canvas-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 1080px;
	z-index: -2;
	background-color: #fff;
	padding: 24px 50px;
	.logo-img {
		width: 980px;
	}
	.title {
		font-size: 15px;
		font-family: LucidaGrande;
		color: #ffffff;
		line-height: 26px;
		height: 26px;
		background: #7030a0;
		text-align: center;
	}
	.finance-info {
		.ant-descriptions-bordered {
			.ant-descriptions-view {
				border: 2px solid #000000;
			}
			.ant-descriptions-item-label {
				background-color: #fff;
				color: #000000;
				width: 200px;
				border-right: 2px solid #000000;
				border-bottom: 2px solid #000000;
			}
			.ant-descriptions-item-content {
				color: #000000;
				text-align: right;
				border-bottom: 2px solid #000000;
			}
			.ant-descriptions-row:last-child {
				.ant-descriptions-item-label,
				.ant-descriptions-item-content {
					border-bottom: none;
				}
			}
		}
		.two-row {
			.ant-descriptions-item-content {
				text-align: center;
				padding: 0;
				span {
					padding: 16px 0;
					width: 130px;
					display: inline-block;
					border-right: 2px solid #000000;
					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}
	.collections-info {
		// display: none;
		.title {
			font-size: 16px;
			line-height: 30px;
			height: 30px;
			background: #261036;
		}
		.amount-statistic {
			display: inline-block;
			.ant-statistic-content-value {
				color: #000;
				font-weight: 600;
			}
		}
		.ant-descriptions-bordered {
			.ant-descriptions-view {
				border: none;
			}
			.ant-descriptions-item-label {
				background-color: #fff;
				color: #000000;
				width: 300px;
				border-right: none;
				border-bottom: none;
				padding: 8px 24px;
			}
			.ant-descriptions-item-content {
				color: #000000;
				font-weight: 600;
				border-bottom: none;
				padding: 8px 24px;
			}
			.ant-descriptions-row:last-child {
				.ant-descriptions-item-label,
				.ant-descriptions-item-content {
					border-bottom: none;
				}
			}
			.ant-descriptions-row {
				border-bottom: none;
				&:last-child {
					.ant-descriptions-item-label {
						display: none;
					}
					.ant-descriptions-item-content {
						font-weight: 400;
					}
				}
			}
		}
		.balance {
			padding: 8px;
			font-weight: 600;
			border-top: 2px solid #261036;
			border-bottom: 1px solid #261036;
		}
		.balance-total {
			padding: 30px 16px 40px;
			font-weight: 600;
		}
		.bottom-addr {
			height: 60px;
			color: #fff;
			padding: 0 80px;
			background-color: #261036;
			overflow: hidden;
			font-size: 12px;
			font-weight: 600;
			.home,
			.phone {
				font-size: 14px;
				vertical-align: middle;
				margin-right: 5px;
				color: goldenrod;
				background-color: #261036;
				border-radius: 100%;
				padding: 5px;
			}
			.home {
				position: relative;
				top: -10px;
				background-color: goldenrod;
				margin-right: 15px;
				color: #fff;
			}
			.tel {
				position: relative;
				z-index: 3;
				font-size: 12px;
			}
			p {
				display: inline-block;
				padding: 12px 0;
			}
			.arrow {
				display: inline-block;
				width: 60px;
				height: 60px;
				border-top: 10px solid #fff;
				border-left: 10px solid #fff;
				transform: rotate(135deg);
				margin-right: 20px;
				&:first-child {
					position: absolute;
					left: -29px;
					background: #261036;
				}
				&:last-child {
					position: absolute;
					right: 0;
					z-index: 2;
					background-color: goldenrod;
				}
			}
			.arrow-bg {
				position: absolute;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 60px;
				background-color: #261036;
				z-index: 1;
			}
			.telphone {
				padding-left: 60px;
				line-height: 60px;
				position: relative;
				color: #261036;
				background-color: goldenrod;
			}
		}
		.block-div {
			.block {
				display: inline-block;
				height: 50px;
				background-color: #f6f0f7;
			}
			.block1 {
				width: 332px;
				background-color: #f6f0f7;
			}
			.block2 {
				position: relative;
				left: -20px;
				width: 100px;
				transform: skewX(-30deg);
				background-color: #ded8df;
			}
			.block3 {
				position: relative;
				left: -20px;
				width: 100px;
				transform: skewX(-30deg);
				background-color: #c5c1c7;
			}
			.block4 {
				position: relative;
				left: -20px;
				width: 100px;
				transform: skewX(-30deg);
				background-color: #aba7ac;
			}
		}
	}
}
.canvas-table {
	border-bottom: 2px solid #000000;
	.ant-table-bordered .ant-table-body > table {
		border: none;
	}
	.ant-table-thead > tr > th {
		background: #7030a0;
		color: #fff;
		height: 100px;
		border: none;
		border-top: 2px solid #000000;
		border-bottom: 2px solid #000000;
	}
	.ant-table-bordered .ant-table-thead > tr > th,
	.ant-table-bordered .ant-table-tbody > tr > td {
		border-left: 2px solid #000000;
		border-bottom: 2px solid #000000;
	}
	.ant-table-bordered .ant-table-thead > tr > th:last-child,
	.ant-table-bordered .ant-table-tbody > tr > td:last-child {
		border-right: 2px solid #000000;
	}
	.ant-table-bordered .ant-table-thead > tr:last-child > th,
	.ant-table-bordered .ant-table-tbody > tr:last-child > td {
		border-bottom: none;
	}
	.ant-table-tbody > tr > td {
		border: none;
	}
	.ant-table-tbody > tr {
		td {
			padding: 0;
		}
		span {
			width: 100%;
			height: 100%;
			display: inline-block;
			padding: 16px;
		}
		.balance {
			background: #ccccff;
		}
	}
}
.collections-table {
	margin-bottom: 20px;
	font-weight: 500;
	.ant-table-bordered .ant-table-body > table {
		border: none;
		border-radius: 0;
	}
	.ant-table-thead > tr > th {
		background: #261036;
		color: #fff;
		border: none;
		padding: 8px 16px;
		font-weight: 600;
	}
	.ant-table-thead > tr:first-child > th:last-child,
	.ant-table-thead > tr:first-child > th:first-child {
		border-radius: 0;
	}
	.ant-table-tbody > tr > td {
		border: none;
		color: #000;
	}
	.ant-table-bordered.ant-table-empty .ant-table-placeholder {
		border: none;
	}
}
</style>
