const moment = require('moment')

export default {
	namespaced: true,
	state: {
		// 映射前端id值到后端id
		pageMap: {
			'menus.permissionMenu.user': {
				sub: {
					addUser: {
						label: 'page.addUser'
					},
					'action.edit': {
						label: 'page.edit'
					},
					'action.stopUser': {
						label: 'page.stopUser'
					},
					'action.delete': {
						label: 'page.delete'
					}
				}
			},
			'menus.permissionMenu.role': {
				sub: {
					addRole: {
						label: 'page.addRole'
					},
					'action.edit': {
						label: 'page.edit'
					},
					'action.delete': {
						label: 'page.delete'
					}
				}
			},
			'menus.manageEmployeeMenu.collector': {
				sub: {
					importEmployeeSheet: {
						label: 'page.importEmployeeSheet'
					},
					switchAttendance: {
						label: 'manageEmployee.switchAttendance'
					},
					exportSheet: {
						label: 'page.exportSheet'
					}
				}
			},
			'menus.reportMenu.performance': {
				sub: {
					exportSheet: {
						label: 'page.exportSheet'
					}
				}
			},
			'menus.reportMenu.holdcase': {
				sub: {
					importHoldcaseSheet: {
						label: 'page.importHoldcaseSheet'
					},
					exportSheet: {
						label: 'page.exportSheet'
					}
				}
			},
			'menus.reportMenu.alloc.main': {
				sub: {
					ReAllocateCases: {
						label: 'alloc.ReAllocateCases'
					},
					caseDistribution: {
						label: 'menus.reportMenu.alloc.caseDistribution'
					},
					ManualAllocCases: {
						// 手动分案入口权限
						label: 'menus.reportMenu.alloc.manual'
					}
				}
			},
			'menus.collectionCoinfigMenu.smsConfig': {
				sub: {
					smsConfiguration: {
						label: 'operation.showSipSetting'
					}
				}
			}
		},
		allPermission: {
			// 完整权限树
			tree: [],
			// dataSource
			dataSource: []
		},


		// 从后端拿的数据
		ActionList: [],
		// 允许显示的id值，结构跟pageMap一致
		visibility: {},
		limitStatus: {
			range: false,
			team: false
		},
		time: {
			min: null,
			max: null,
			rangeLimit: function () {},
			dateLimit: function (tMin) {
				let min = this.min
				if (tMin) {
					if (tMin > min) {
						min = tMin
					}
				}
				return function (start) {
					if (start > min) {
						return false
					}
					return true
				}
			}
		},
		range: [],
		team: [],
		roleIdList: [],
		roleIdListAll: [],
		roleIdListMap: [],
		isAdmin: false,
		userPermissions: [], // 当前用户权限
		tree: []
	},
	mutations: {
		init: (state, model) => {
			// 存一份原始按钮权限
			state.ActionList = model.ActionList

			// 数据权限
			let dataLimitList = model.data_permission || []
			// 设置时间限制
			state.limitStatus.range = false
			state.limitStatus.team = false
			for (let limit of dataLimitList) {
				if (limit.data_type === 1) {
					let v = Number(limit.limit_detail)
					let min = moment().add(-v, 'day')
					state.time.min = min
					state.time.rangeLimit = function ([start, end]) {
						if (start > min) {
							return false
						}
						return true
					}
				}
				if (limit.data_type === 2) {
					state.limitStatus.range = true
					state.range = limit.limit_detail
					state.range.push('', 'all')
				}
				if (limit.data_type === 3) {
					state.limitStatus.team = true
					state.team = limit.limit_detail.map(Number)
					state.team.push('', 'all')
				}
			}
		},
		setVisibility: (state, model) => {
			state.visibility = model
		},
		setAllPermission: (state, model) => {
			state.allPermission.tree = model
			let dataSource = []
			let finalParentList = []
			// let ActionList = []
			// 一级菜单
			for (let i of model) {
				if (i.parentPermissionId === '0' || i.parentPermissionId === 'app_0') {
					const firstMenu = JSON.parse(JSON.stringify(i))
					firstMenu.sub = []
					finalParentList.push(firstMenu)
				}
			}
			// 二级菜单
			for (let i of model) {
				if (i.parentPermissionId !== null && (i.parentPermissionId !== '0' || i.parentPermissionId !== 'app_0')) {
					const firstMenu = finalParentList.find((v) => v.id === i.parentPermissionId)
					if (!firstMenu) {
						continue
					}
					const secondMenu = JSON.parse(JSON.stringify(i))
					secondMenu.sub = []
					// 二级菜单页面权限
					model.forEach((j) => {
						if (j.parentPermissionId === secondMenu.id) {
							secondMenu.sub.push(j)
						}
					})
					firstMenu.sub.push(secondMenu)
				}
			}
			let allPermission = finalParentList
			state.tree = allPermission
			// for (let actions of ActionList) {
			// 	let subPermission = allPermission.filter((i) => i.sub.filter((j) => j.id === actions.id).length)[0]
			// 	subPermission = subPermission.sub.filter((j) => j.id === actions.id)[0]
			// 	subPermission.sub = actions.sub
			// }

			for (let firstMenu of allPermission) {
				let isFirst = true
				for (let secondMenu of firstMenu.sub) {
					let oneLine = {
						first: [],
						second: [],
						third: [],
						type: ''
					}
					if (isFirst) {
						oneLine.first.push({
							value: firstMenu.id,
							label: firstMenu.permissionName
						})
					}
					oneLine.second.push({
						value: secondMenu.id,
						label: secondMenu.permissionName
					})
					if (secondMenu.sub.length > 0) {
						for (let secondMenuPermission of secondMenu.sub) {
							try {
								oneLine.third.push({
									value: secondMenuPermission.id,
									label: secondMenuPermission.permissionName
								})
							} catch (e) {}
						}
					}
					oneLine.type = firstMenu.type
					oneLine.indexVal = dataSource.length
					dataSource.push(oneLine)
					isFirst = false
				}
				if (firstMenu.sub.length === 0 && firstMenu.permissionName != 'SALESMAN') {
					let obj = {}
					obj.first = [
						{
							value: firstMenu.id,
							label: firstMenu.permissionName
						}
					]
					obj.second = []
					obj.third = []
					obj.type = firstMenu.type
					obj.indexVal = dataSource.length
					dataSource.push(obj)
				}
			}
			state.allPermission.dataSource = dataSource
		},
		setRoleId: (state, model) => {
			let roleIdList = []
			let roleIdListMap = {}
			for (let role of model) {
				roleIdList.push({
					label: role.roleName,
					value: role.id
				})
				roleIdListMap[role.id] = role.roleName
			}
			let roleIdListAll = JSON.parse(JSON.stringify(roleIdList))
			// roleIdListAll.unshift({
			// 	label: 'table.all',
			// 	value: ''
			// })
			state.roleIdList = roleIdList
			state.roleIdListAll = roleIdListAll
			state.roleIdListMap = roleIdListMap
		},
		setAdminStatu: (state, model) => {
			state.isAdmin = model
		},
		setUserPermissions: (state, value) => {
			state.userPermissions = value.map(i => i.authority)
		}
	}
}
