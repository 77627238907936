import { isInteger, gt, gte, lt, lte, inRange } from 'lodash'
import store from '@/store'

export const isDigit = (s) => '1234567890'.includes(s)

export const isLetter = (s) => 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.includes(s)

export const hasDigit = (s) => {
	for (const e of s) {
		if (isDigit(e)) {
			return true
		}
	}
	return false
}

export const hasLetter = (s) => {
	for (const e of s) {
		if (isLetter(e)) {
			return true
		}
	}
	return false
}

/**
 * 全部大写转换成小写并以"-"符号连接
 * @key {Stirng} key
 */
export const lowercaseLetter = function (key) {
	let reg = /[A-Z]/g
	let reg2 = /^-/g
	key = key.replace(reg, function (k) {
		return '-' + k
	})
	return key.replace(reg2, '').toLowerCase()
}

/**
 * 生成随机颜色
 */
export const randomColor = () => {
	let r = Math.floor(Math.random() * 256)
	let g = Math.floor(Math.random() * 256)
	let b = Math.floor(Math.random() * 256)
	let color = '#' + r.toString(16) + g.toString(16) + b.toString(16)
	return color
}

/**
 * phone号码屏蔽
 */
export const encryPhone = (num) => {
	return num.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
}

/**
 * Demo: parseTime(new Date('2018-12-31'),'{y}年{m}月{d}日 周{a}') -> 2018年12月31日 周一
 * @param {*} time 时间戳或者时间对象
 * @param {*} fm 格式 默认是{y}-{m}-{d} {h}:{i}:{s}
 */
export const parseTime = (time, fm) => {
	const format = fm || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (('' + time).length === 10) time = parseInt(time) * 1000
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const timeStr = format.replace(/(y|m|d|h|i|s|a)+/g, (result, key) => {
		let value = formatObj[key]
		if (key === 'a') return ['日', '一', '二', '三', '四', '五', '六'][value]
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return timeStr
}

// 获取当前月的第一天
export const getCurrentMonthFirst = () => {
	let date = new Date()
	date.setDate(1)
	return date
}

// 获取当前月的最后一天
export const getCurrentMonthLast = () => {
	let date = new Date()
	let currentMonth = date.getMonth()
	let nextMonth = ++currentMonth
	let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1)
	let oneDay = 1000 * 60 * 60 * 24
	return new Date(nextMonthFirstDay - oneDay)
}

/**
 * 转换枚举类型的名字
 * @param {String} arrs 枚举数组类型
 * @param {any} v 当前值
 */
export const labelType = (arrs, v) => {
	if (!v && v !== 0) return v
	let index = arrs.findIndex((item) => {
		return item.value.toString() === v.toString()
	})
	if (index === -1) {
		return v + ':unknow'
	} else {
		return arrs[index].label || arrs[index].name
	}
}

// 返回唯一key值的对象树的某一条结构
export const diguiTree = (tree, v, fartherTree) => {
	for (let i in tree) {
		if (tree[i].value === v) {
			fartherTree = {
				value: tree[i].value,
				label: tree[i].label
			}
			break
		} else {
			if (tree[i].children instanceof Array && tree[i].children.length >= 0) {
				let sonTree = diguiTree(tree[i].children, v, {})
				if (sonTree.hasOwnProperty('value')) {
					fartherTree = {
						value: tree[i].value,
						label: tree[i].label,
						children: [sonTree]
					}
				}
			}
		}
	}
	return fartherTree
}
// 返回唯一key值的对象树的数组结构
export const diguiTree2 = (tree, v, fartherTree) => {
	for (let i in tree) {
		if (tree[i].value === v) {
			fartherTree.push({
				value: tree[i].value,
				label: tree[i].label
			})
			// 直到找到符合条件后终止循环
			// break
		} else {
			if (tree[i].children instanceof Array && tree[i].children.length >= 0) {
				let sonTree = diguiTree2(tree[i].children, v, [])
				if (sonTree.length > 0) {
					fartherTree.push({
						value: tree[i].value,
						label: tree[i].label,
						children: sonTree
					})
				}
			}
		}
	}
	return fartherTree
}

// 返回唯一key值的对象树结构
export const diguiTree3 = (tree, v) => {
	for (let i of tree) {
		if (i.value === v) {
			i.show = true
			continue
		} else {
			if (i.children) {
				diguiTree3(i.children, v)
			}
		}
	}
	return tree
}
export const toPercent = (point) => {
	point = Number(point)
	if (point === 0) {
		return 0
	}
	var str = Number(point * 100).toFixed()
	str += '%'
	return str
}

export const transForDuration = (value) => {
	var theTime = parseInt(value / 1000) // 需要转换的时间秒
	// var theTime = parseInt(value)// 需要转换的时间秒
	var s = 0
	var m = 0
	var h = 0
	var d = 0
	if (theTime > 0) {
		s = parseInt(theTime % 60)
		var temp = parseInt(theTime / 60)
		if (temp > 0) {
			m = parseInt(temp % 60)
			var temp1 = parseInt(temp / 60)
			if (temp1 > 0) {
				h = parseInt(temp1 % 24)
				var temp2 = parseInt(temp1 / 24)
				if (temp2 > 0) {
					d = temp2
				}
			}
		}
	}

	if (d > 0) {
		return d + 'day' + h + 'h' + m + 'm' + s + 's'
	} else if (h > 0) {
		return h + 'h' + m + 'm' + s + 's'
	} else if (m > 0) {
		return m + 'm' + s + 's'
	} else if (s > 0) {
		return s + 's'
	} else {
		return ''
	}

	// var theTime1 = 0// 分
	// var theTime2 = 0// 小时
	// if (theTime > 60) {
	//   theTime1 = parseInt(theTime / 60)
	//   theTime = parseInt(theTime % 60)
	//   if (theTime1 > 60) {
	//     theTime2 = parseInt(theTime1 / 60)
	//     theTime1 = parseInt(theTime1 % 60)
	//     if (theTime2 > 24) {
	//       // 大于24小时
	//       theTime2 = parseInt(theTime2 % 24)
	//     }
	//   }
	// }
	// var result = ''
	// if (theTime > 0) {
	//   result = '' + parseInt(theTime) + 's'
	// }
	// if (theTime1 > 0) {
	//   result = '' + parseInt(theTime1) + 'm' + result
	// }
	// if (theTime2 > 0) {
	//   result = '' + parseInt(theTime2) + 'h' + result
	// }
	// return result
}

export const productToCountry = {
	ipesa: 'kenya',
	kashway: 'kenya',
	xcredit: 'nigeria',
	iMoney: 'nigeria',
	rsrush: 'india',
	ipeso: 'mexico',
	kabilcash: 'mexico',
	maxcredito: 'mexico',
	creavicash: 'mexico'
}

export const Rule = {
	requiredSelect: {
		required: true,
		message: () => 'Please select',
		trigger: 'blur'
	},
	requiredInput: {
		required: true,
		message: () => 'Please input',
		trigger: 'blur'
	},
	// 输入数字大小范围
	inputNumberRange: function (min, max, errMsg = '') {
		return {
			validator: (rule, value, callback) => {
				const result = inRange(Number(value), min, max + 1)
				if (!result) {
					callback(errMsg || `Please enter a value in the range ${min} to ${max}`)
				} else {
					callback()
				}
			},
			trigger: 'blur'
		}
	},
	// 输入数字大于，isEquality是否包含等于
	inputGreaterThan: function (min, isEquality = false, errMsg = '') {
		return {
			validator: (rule, value, callback) => {
				const compareFunc = isEquality ? gte : gt
				if (compareFunc(Number(value), min)) {
					callback()
				} else {
					callback(errMsg || `Please enter a number greater than ${min}.`)
				}
			},
			trigger: 'blur'
		}
	},
	// 输入数字小于，isEquality是否包含等于
	inputLessThan: function (min, isEquality = false, errMsg = '') {
		return {
			validator: (rule, value, callback) => {
				const compareFunc = isEquality ? lte : lt
				if (compareFunc(Number(value), min)) {
					callback()
				} else {
					callback(errMsg || `Please enter a number less than ${min}.`)
				}
			},
			trigger: 'blur'
		}
	},
	// 输入整数
	inputInteger: function (errMsg = '') {
		return {
			validator: (rule, value, callback) => {
				if (isInteger(Number(value))) {
					callback()
				} else {
					callback(new Error(errMsg || 'Please enter integer'))
				}
			},
			trigger: 'blur'
		}
	},
	// floatNum: 小数点后位数不能多于2位
	inputFloat: function (floatNum = 2) {
		return {
			validator: (rule, value, callback) => {
				if (isFinite(Number(value))) {
					if (value.toString().split('.')[1]?.length > floatNum) {
						callback(new Error(`Can not exceed ${floatNum} digits after the decimal point`))
					} else {
						callback()
					}
				} else {
					callback(new Error('Please enter a number.'))
				}
			},
			trigger: 'blur'
		}
	},
	// floatNum: 小数点后位数必须等于2位
	inputFloatDecimal: function (floatNum = 2) {
		return {
			validator: (rule, value, callback) => {
				if (isFinite(Number(value))) {
					if (value.toString().split('.')[1]?.length === floatNum) {
						callback()
					} else {
						callback(new Error(`Please enter number has ${floatNum} decimal.`))
					}
				} else {
					callback(new Error('Please enter a number.'))
				}
			},
			trigger: 'blur'
		}
	},
	// 输入数字位数长度
	inputNumberLength: function (min, max, errMsg = '') {
		return {
			validator: (rule, value, callback) => {
				if (isFinite(Number(value))) {
					if (value.toString()?.length >= min && value.toString()?.length <= max) {
						callback()
					} else if (min === max) {
						callback(new Error(errMsg || `Please enter a number length equal ${min}`))
					} else {
						callback(new Error(errMsg || `Please enter a number length between ${min} and ${max}`))
					}
				} else {
					callback(new Error(errMsg || 'Please enter a number.'))
				}
			},
			trigger: 'blur'
		}
	},
	// 输入大小写字母+数字
	inputLetterNumber: function (errMsg = '') {
		return {
			validator: (rule, value, callback) => {
				if (value === '' || /^[a-zA-Z0-9]+$/.test(value)) {
					callback()
				} else {
					callback(new Error(errMsg || 'Upper and lower case letters + numbers only'))
				}
			},
			trigger: 'blur'
		}
	},
}

/**
 *  判断类型
 */
const class2type = {}
;['Array', 'Date', 'RegExp', 'Object', 'Error', 'Map', 'Set'].forEach((e) => {
	class2type['[object ' + e + ']'] = e.toLowerCase()
})

export const type = (obj) => {
	if (obj == null) return String(obj)
	return typeof obj === 'object' ? class2type[Object.prototype.toString.call(obj)] || 'object' : typeof obj
}

// 重置表单数据的初始值
export const resetFormData = (formData) => {
	for (const k in formData) {
		switch (type(formData[k])) {
			case 'string':
			case 'number':
			case 'boolean':
				formData[k] = ''
				break
			case 'array':
				formData[k] = []
				break
			case 'object':
				formData[k] = {}
				break
			default:
				break
		}
	}
	return formData
}

/**
 * 把target在obj中对应的key的value拷贝过去（用于初始化表单的值，而不引入不需要的key）
 * @param {*} obj 通常是formData
 * @param {*} target 通常是传入的itemData
 * @param {*} filterArr 要过滤掉的，有值也不拷贝的key
 */
export const cloneTargetValue = (obj, target, filterArr = []) => {
	for (const k in obj) {
		if (target?.[k] !== undefined && !filterArr.includes(k)) obj[k] = target[k] // eslint-disable-line
	}
	return obj
}

export const getBankInfo = (bankCode) => {
	return store.getters.enumerate.bankList.find((i) => i.code === bankCode) || {}
}

export const hasPermission = (permissionName) => {
	return store.getters.permission.userPermissions.includes(permissionName)
}
