'use strict'

import Vue from 'vue'
import { Message } from 'ant-design-vue'
import router from '@/router/index'
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
const config = {
	// baseURL: process.env.NODE_ENV === 'development' ? `${location.origin}${process.env.VUE_APP_PATH_URL}` : `${location.origin}`,
	baseURL: process.env.NODE_ENV === 'development' ? `${location.origin}/bccms` : `${location.origin}`,
	notify: true, // 默认可以通知
	timeout: 60 * 1000, // Timeout
	withCredentials: true // Check cross-site Access-Control
}
export const _axios = axios.create(config)
export const api = (url, dataJson, method = 'post') => {
	let token = localStorage.getItem('token')
	return _axios({
		url: `/api${url}`,
		data: { ...dataJson, token },
		method: method
	})
}
_axios.interceptors.request.use(
	function (config) {
		let token = sessionStorage.getItem('token')
		config.headers.Authorization = token
		if (config.method === 'get' || config.method === 'delete') {
			config.params = config.data
		}
		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		let myData = response.data
		if (response.status === 200) {
			if (response.config.responseType === 'arraybuffer') {
				return myData
			}
			if (myData.code === 200) {
				if (myData.msg && response.config.notify) Message.success(myData.msg)
				return myData.data
			} else if (myData.code === 'expired') {
				// 跳转到登录页
				return router.replace('/login')
			} else {
				if (response.config.notify) Message.error(myData.message)
				return Promise.reject(myData)
			}
		} else {
			return Promise.reject(new Error('status not 200!'))
		}
	},
	function (error) {
		return Promise.reject(error)
	}
)

Plugin.install = function (Vue, options) {
	Vue.axios = _axios
	window.axios = _axios
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios
			}
		},
		$axios: {
			get() {
				return _axios
			}
		}
	})
}

Vue.use(Plugin)

export default Plugin
