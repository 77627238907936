/*
 * @Author: LPL
 * @Date: 2019-11-18 10:43:15
 * @LastEditTime: 2019-11-18 18:03:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \collection\src\store\modules\table.js
 */
// 表格查询参数store
const table = {
  state: {
    tableParm: {
      page: 1,
      pagesize: 25
    },
    tableData: [],
    tableSize: 0,
    searchParm: {}
  },
  mutations: {
    // 动态页面参数
    setTableParm: (state, obj) => {
      state['tableParm'][obj.type] = obj.value
    },
    /**
     * 动态查询参数 如果obj为false,初始化为state['searchParm']={},否则新增属性obj
     * @param state
     * @param obj
     */
    setSearchParm: (state, obj) => {
      if (!obj) {
        state['searchParm'] = {}
      }
      // 对象展开运算符添加对象属性
      // 当需要在对象上添加新属性时，你应该使用 Vue.set(obj, 'newProp', 123), 或者以新对象替换老对象。例如，利用 stage-3 的对象展开运算符
      state['searchParm'] = { ...state['searchParm'], ...obj }
    },
    /**
     * 动态获取表数据 如果obj为false,初始化为state['searchParm']={},否则新增属性obj
     * @param state
     * @param obj
     */
    setTableData: (state, obj) => {
      if (!obj) {
        state.tableData = []
        state.tableSize = 0
      } else {
        state.tableData = obj.list
        state.tableSize = obj.total
      }
    }
  },
  actions: {
    setTableParm: (context, obj) => {
      context.commit('setTableParm', obj)
    },
    setSearchParm: (context, obj) => {
      context.commit('setSearchParm', obj)
    },
    // 通过Promis对象参数获取数据保存到store中,获取失败初始化数据
    setTableData: (context, promise) => {
      promise.then(function (res) {
        context.commit('setTableData', res.data)
      }).catch(function (res) {
        // 这个以后要加上,为了初始化表格数据
        context.commit('setTableData')
      })
    }
  }
}

export default table
