import { _axios } from '@/plugins/axios'

// 获得枚举
export const apiGetCode = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/code`,
    method: method,
    data: dataJson
  })
}

// 根据枚举名获得枚举
export const apiGetCodeByName = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/code/getByName`,
    method: method,
    data: dataJson
  })
}

// 获得所有枚举
export const apiGetCodeList = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/code/list`,
    method: method,
    data: dataJson
  })
}

// 添加自定义枚举
export const apiCreateCode = (dataJson, method = 'post') => {
  return _axios({
    url: `/car/code`,
    method: method,
    data: dataJson
  })
}

// 增加或者删除枚举值
export const apiCreateCodeValue = (dataJson, method = 'post') => {
  return _axios({
    url: `/car/code/values`,
    method: method,
    data: dataJson
  })
}
