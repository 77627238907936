<template>
	<a-spin :spinning="pageState.uploadState" :tip="pageState.loadingText" size="large">
		<router-view />
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'app',
	computed: {
		...mapGetters(['setting', 'pageState'])
	},
	created() {
		this.$nextTick(() => {
			this.$i18n.locale = this.setting.curLanguage.locale
			this.$store.commit('pageState/setUploadState', false)
		})
	},
	watch: {
		'setting.curLanguage.locale'() {
			this.$i18n.locale = this.setting.curLanguage.locale
		}
	},
	mounted() {
		setTimeout(() => {
			if (this.$router.currentRoute.name && this.$router.currentRoute.name !== 'login') {
				this.$store.dispatch('enumerate/initEnums')
			}
		}, 500)
	}
}
</script>

<style lang="less">
@import './styles/index.less';
@import 'common.less';
@import 'public.less';
.spin-content {
	border: 1px solid #91d5ff;
	background-color: #e6f7ff;
	padding: 30px;
}
</style>
