/*
 * @Author: your name
 * @Date: 2019-11-18 10:43:15
 * @LastEditTime: 2019-11-26 15:24:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \collection\src\store\modules\user.js
 */
export default {
  namespaced: true,
  state: {
    prefixApi: '',
    userId: '',
    isLogined: false,
    teamName: '',
    productName: '',
    role: '',
    loanInterstRate: null,
  },
  mutations: {
    initLoginUser: (state) => {
      state['userId'] = ''
      state['isLogined'] = false
    },
    setLoginUser: (state, data) => {
      let { product, teamName, userId, isLogined, role, userName } = data

      state['teamName'] = teamName || state['teamName']
      state['productName'] = product || state['productName']
      state['userId'] = userId || state['userId']
      state['userName'] = userName || state['userName']
      state['isLogined'] = isLogined || state['isLogined']
    },
    setLoanInterstRate: (state, data) => {
      state.loanInterstRate = data
    },
    setUserPerformance: (state, data) => {
      if (data.due) {
        state.duePerformance = data.due
      }
      if (data.overDue) {
        state.overDuePerformance = data.overDue
      }
    }
  }
}
