export default {
  namespaced: true,
  state: {
    maturity: {
      selectObj: null
    },
    overDue: {
      selectObj: null
    }
  },
  mutations: {
    maturitySelect: (state, model) => {
      state.maturity.selectObj = model
    },
    overDueSelect: (state, model) => {
      state.overDue.selectObj = model
    }
  }
}
