import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import VuexPersistence from 'vuex-persist'
import getters from '@/store/getters'
import navMenu from '@/store/modules/navMenu'
import table from '@/store/modules/table'
import setting from '@/store/modules/setting'
import tabs from '@/store/modules/tabs'
import user from '@/store/modules/user'
import callCenter from '@/store/modules/callCenter'
import enumerate from '@/store/modules/enumerate'
import permission from '@/store/modules/permission'
import bankCode from '@/store/modules/bankCode'
import pageState from '@/store/modules/pageState'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
// 解决vuex刷新问题
// 参考http://www.orzzone.com/vuex-data-persistence.html
const vuexLocal = new VuexPersistence({
	storage: window.sessionStorage
	// reducer: state => ({
	//   loginUser: state.loginUser,
	//   oneMember: state.member
	// }),
	// filter: (mutation) => (
	//   mutation.type === 'setLoginUser' ||
	//   mutation.type === 'setWechat' ||
	//   mutation.type === 'setCar' ||
	//   mutation.type === 'setOrder' ||
	//   mutation.type === 'setLoginUser'
	// )
})

export default new Vuex.Store({
	modules: {
		setting,
		user,
		tabs,
		navMenu,
		table,
		callCenter,
		enumerate,
		permission,
		bankCode,
		pageState
	},
	getters: getters,
	strict: debug,
	plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin]
})
