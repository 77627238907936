import { apiGetCodeByName } from '@/api/code'
export default {
  namespaced: true,
  state: {
    banklist: [],
  },
  mutations: {
    changebanklist(state, banklist) {
      state.banklist = banklist;
    }
  },
  actions: {
    asyncBanklist({ commit }) {
      apiGetCodeByName({ name: 'BANK_NAME' }).then(res => {
        commit('changebanklist', res.values)
      })
    }
  }

}
