export default {
	namespaced: true,
	state: {
		historyTabs: [],
		activeTab: {
			name: '',
			fullPath: ''
		}
	},
	mutations: {
		// 添加标签
		addTab: (state, view) => {
			// 如果访问路径相同,不添加进数组
			let { name, fullPath } = view
			let obj = { name, fullPath }
			state.activeTab = obj
			let historyTabs = state.historyTabs
			let flag = historyTabs.some((v) => {
				return v.fullPath === fullPath.split('#')[0]
			})
			if (!flag) {
				state.historyTabs.push(obj)
			}
			// let len = state.length
			// 数组最大容量为7,多了把前面的cut掉
			// if (len > 7) {
			//   state.historyTabs = state.historyTabs.slice(1, len)
			// }
		},
		setActiveTab: (state, key) => {
			let index = state.historyTabs.findIndex((item) => item.fullPath === key)
			state.activeTab = state.historyTabs[index]
		},
		// 删除标签
		delTab: (state, fullPath) => {
			let index = state.historyTabs.findIndex((item) => item.fullPath === fullPath)
			if (index > -1) state.historyTabs.splice(index, 1)
		},
		clearTab: (state, fullPath) => {
			state.historyTabs = []
		}
	},
	actions: {
		addTag: (context, view) => {
			context.commit('addTab', view)
		},
		deleTag: (context, view) => {
			context.commit('delTab', view)
		}
	}
}
