<template>
	<div>
		<a-form v-if="formSearchList" class="search-form" layout="inline" :form="searchFormTable">
			<a-form-item :label="$t(formItem.label)" v-for="(formItem, index) in formSearchList" :key="index">
				<a-input v-if="formItem.type === 'input'" v-decorator="formSearchList[index].validateField" />
				<a-select
					v-else-if="formItem.type === 'select'"
					v-decorator="formSearchList[index].validateField"
					@change="formSearchList[index].selectChange"
				>
					<a-select-option value="">
						{{ $t('table.all') }}
					</a-select-option>
					<template v-if="formItem.dataPreType">
						<a-select-option :value="selectItem[formItem.dataPreType.value]" v-for="(selectItem, index) in formItem.selectOption" :key="index">
							{{ selectItem[formItem.dataPreType.text] }}
						</a-select-option>
					</template>
					<template v-else>
						<a-select-option :value="selectItem" v-for="(selectItem, index) in formItem.selectOption" :key="index">
							{{ formItem.prefix ? $t(`${formItem.prefix}.${selectItem}`) : selectItem }}
						</a-select-option>
					</template>
				</a-select>
				<a-range-picker
					v-else-if="formItem.type === 'range-picker'"
					v-decorator="formSearchList[index].validateField"
					:format="dateFormat"
					:initialValue="[moment(new Date().setMonth(new Date().getMonth() - 1)), moment()]"
				/>
				<span v-else-if="formItem.type === 'scope-input-number'">
					<a-input-number v-decorator="formSearchList[index].validateField[0]" :precision="0" :step="1" />
					-
					<a-input-number v-decorator="formSearchList[index].validateField[1]" :precision="0" :step="1" />
				</span>
			</a-form-item>
			<a-form-item>
				<a-button type="primary" @click="initTable(1)" v-t.preserve="'operate.search'"></a-button>
			</a-form-item>
			<a-form-item>
				<slot name="btn"></slot>
			</a-form-item>
		</a-form>
		<slot name="toggleTable"></slot>
		<a-table
			:loading="loading"
			:columns="columns"
			:rowKey="(record, index) => index"
			:dataSource="dataSource"
			size="default"
			:pagination="pagination"
			@change="handleTableChange"
			:scroll="{ x: true }"
			:row-selection="upfrontFeesRowSelection"
			bordered
		>
			<a-statistic slot="money_format" slot-scope="text" :precision="2" :value="text === null ? ' - ' : text" />
			<span slot="serial_number" slot-scope="obj, text, index">
				{{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
			</span>
			<span slot="scopedSlots" slot-scope="obj">
				<slot name="scopedSlots" :columnData="obj"></slot>
			</span>
			<span slot="scopedSlots2" slot-scope="obj">
				<slot name="scopedSlots2" :columnData="obj"></slot>
			</span>
			<span slot="action" slot-scope="obj">
				<slot name="action" :columnData="obj"></slot>
			</span>
		</a-table>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	props: {
		upfrontFeesRowSelection: {},
		formSearchList: {
			type: Array
		},
		columns: {
			type: Array,
			require: true
		},
		initDataSource: {
			type: Function,
			require: true
		}
	},
	data() {
		return {
			dateFormat: 'YYYY-MM-DD',
			searchFormTable: this.$form.createForm(this, { name: 'searchFormTable' }),
			loading: false,
			searchParams: {},
			dataSource: [],
			pagination: {
				total: 0,
				showTotal: (total) => `Total ${total} items`,
				hideOnSinglePage: false,
				current: 1,
				showSizeChanger: true,
				pageSize: 10,
				pageSizeOptions: ['10', '20', '50', '100']
			},
			sorter: {}
		}
	},
	created() {
		this.$nextTick(() => {
			this.initTable(1)
		})
	},
	methods: {
		moment: moment,
		handleTableChange(pagination, filters, sorter) {
			let { current, pageSize } = pagination
			this.pagination.current = current
			this.pagination.pageSize = pageSize
			this.sorter = sorter
			this.initTable()
		},
		removeUnuseParam(params) {
			for (let key in params) {
				if (params[key] === undefined || params[key] === '') {
					delete params[key]
				}
			}
			return params
		},
		initTable(initPageNum) {
			this.dataSource = []
			this.loading = true
			if (initPageNum) {
				this.pagination.current = initPageNum
			}
			this.searchFormTable.validateFields().then(async (searchParams) => {
				let initParams = {
					...searchParams,
					pageNum: this.pagination.current - 1,
					pageSize: this.pagination.pageSize
				}
				if (this.formSearchList) {
					// 时间转换
					this.formSearchList.map((item) => {
						if (item.type === 'range-picker') {
							if (item.paramsName) {
								initParams[item.paramsName[0]] =
									searchParams[item.validateField[0]][0] && searchParams[item.validateField[0]][0].format(this.dateFormat) + ' 00:00:00'
								initParams[item.paramsName[1]] =
									searchParams[item.validateField[0]][1] && searchParams[item.validateField[0]][1].format(this.dateFormat) + ' 23:59:59'
							} else {
								initParams[`${item.validateField[0]}Begin`] =
									searchParams[item.validateField[0]][0] && searchParams[item.validateField[0]][0].format(this.dateFormat) + ' 00:00:00'
								initParams[`${item.validateField[0]}End`] =
									searchParams[item.validateField[0]][1] && searchParams[item.validateField[0]][1].format(this.dateFormat) + ' 23:59:59'
							}
							delete initParams[item.validateField[0]]
						}
					})
				}
				initParams = this.removeUnuseParam(initParams)
				if (initParams.registrationNumber) {
					initParams.registrationNumber = initParams.registrationNumber.replace(/\s*/g, '')
				}
				if (initParams.caseId) {
					initParams.casePrefix = initParams.caseId.split('-')[0]
					initParams.caseId = initParams.caseId.split('-')[1]
				}
				let res = await this.initDataSource(initParams, this.sorter)
				this.dataSource = res.pageItems ? res.pageItems : res.content
				this.pagination.current = res.pageNum !== undefined ? res.pageNum + 1 : res.number + 1
				this.pagination.pageSize = res.pageSize !== undefined ? res.pageSize : res.size
				this.pagination.total = res.totalFilteredRecords !== undefined ? res.totalFilteredRecords : res.totalElements
				this.loading = false
			})
		},
		handleSelectChange(value) {
			// (formSearchList[index].selectChange)
			// fun ? fun() : ''
		}
	}
}
</script>
<style lang="less" scoped>
.search-form {
	margin-bottom: 20px;
	.ant-input,
	.ant-select {
		width: 200px;
	}
}
.menuTree {
	text-align: left;
}
</style>
