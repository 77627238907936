<template>
  <div class="clearfix">
    <a-row v-show="message.title">
      <a-alert
        v-show="show.upload === 'success'"
        :message="message.title"
        :description="message.reason"
        type="success"
        show-icon
      />

      <a-alert
        v-show="show.upload === 'fail'"
        :message="message.title"
        :description="message.reason"
        type="error"
        show-icon
      />
    </a-row>

    <div v-show="!uploadDone">
      <a-form layout="inline" :form="tableForm">
        <!--        产品-->
        <a-form-item :label="$t('table.caseProduct')">
          <a-select v-decorator="validateFields.productId" style="min-width: 120px">
            <a-select-option :value="item.value" v-for="item in enumerate.productId" :key="item.value">
              {{$t(item.label)}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <br />

        <a-form-item :label="$t('note.uploadHoldCase.effectTime')">
          <a-radio-group v-decorator="validateFields.effectTime">
            <a-radio value="today">
              <span class="allocTitle">
                {{$t('note.uploadHoldCase.timeSelector.today') + ' (' + new Date().toLocaleDateString() + ')'}}
              </span>
            </a-radio>
            <br />
            <a-radio value="tomorrow">
              <span class="allocTitle">
                {{$t('note.uploadHoldCase.timeSelector.tomorrow') + ' (' + new Date(Date.now() + 24*60*60*1000).toLocaleDateString() + ')'}}
              </span>
            </a-radio>

          </a-radio-group>

        </a-form-item>

      </a-form>

      <a-row type="flex" justify="space-around" align="middle">
        <div style="margin-top: 100px">

          <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
            <a-button> <a-icon type="upload" /> Select File </a-button>
          </a-upload>

          <a-button
            type="primary"
            :disabled="fileList.length === 0"
            :loading="uploading"
            style="margin-top: 16px"
            @click="handleUpload"
          >
            {{ uploading ? 'Uploading' : 'Start Upload' }}
          </a-button>

        </div>
      </a-row>
    </div>
  </div>
</template>

<script>
import { apiUploadEmployeeDailyCsv, apiUploadHoldcaseCsv } from '@/api/user'
import { mapGetters } from 'vuex'

const effectTimeNum = {
  'today': 1,
  'tomorrow': 0,
}
export default {
  data () {
    return {
      tableForm: this.$form.createForm(this, { name: 'tableForm' }),
      validateFields: {
        productId: [
          'product',
          {
            initialValue: this.$store.state.enumerate.productId[0].value,
          }
        ],
        effectTime: [
          'effectTime',
          {
            initialValue: 'today',
          }
        ]
      },
      fileList: [],
      uploading: false,
      message: {
        title: '',
        reason: '',
      },
      uploadDone: false,
      show: {
        upload: '',
      },
    }
  },
  computed: {
    ...mapGetters(['user', 'enumerate', 'setting']),
  },
  methods: {
    onClose () {
      this.show.upload = ''
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload (file) {
      this.fileList = [file]
      return false
    },
    async handleUpload () {
      this.uploading = true

      let data = await this.tableForm.validateFields()
      const { fileList } = this
      const formData = new FormData()
      formData.append('file', fileList[0])
      formData.append('product', data.product)
      formData.append('today', effectTimeNum[data.effectTime])

      try {
        let result = await apiUploadHoldcaseCsv(formData)
        this.show.upload = 'success'
        this.message.title = this.$t('uploadStatus.success')
        this.uploadDone = true
      } catch (e) {
        this.show.upload = 'fail'
        this.message.title = this.$t('uploadStatus.fail')
        this.message.reason = e.msg
      }
      this.uploading = false
    },
  },
}
</script>
